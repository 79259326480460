import React from "react";
import { TextField } from '@rmwc/textfield';
import {
  DialogTitle,
  DialogContent
} from '@rmwc/dialog';

import '@material/dialog/dist/mdc.dialog.css';
import '@material/textfield/dist/mdc.textfield.css';
import '@material/floating-label/dist/mdc.floating-label.css';
import '@material/notched-outline/dist/mdc.notched-outline.css';
import '@material/line-ripple/dist/mdc.line-ripple.css';

class ShaftHandleOffset extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      distanceparameters: props.distanceparameters,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
      this.setState({
        distanceparameters: {
          ...this.state.distanceparameters,
          shaftToHandleOffset: event.target.valueAsNumber,
        },
      }, () => {
        this.props.onChange(this.state.distanceparameters);
      });
  }
  render() {
    return (
      <div>
        <DialogTitle>Shaft to handle offset distance (mm)</DialogTitle>
        <DialogContent style={{paddingTop: '15px'}}>
          <TextField
            id="shaftToHandleOffset"
            type="number"
            outlined
            style={{width: '100%'}}
            label="Shaft to handle offset distance"
            value={this.state.distanceparameters.shaftToHandleOffset}
            onChange={(event) => this.handleChange(event)}/>
        </DialogContent>
      </div>
    );
  };
};

export default ShaftHandleOffset;