import React from "react";
import { TextField } from '@rmwc/textfield';
import {
  DialogTitle,
  DialogContent
} from '@rmwc/dialog';

import '@material/dialog/dist/mdc.dialog.css';
import '@material/textfield/dist/mdc.textfield.css';
import '@material/floating-label/dist/mdc.floating-label.css';
import '@material/notched-outline/dist/mdc.notched-outline.css';
import '@material/line-ripple/dist/mdc.line-ripple.css';

class DatumToChannel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      manufacturerparameters: props.manufacturerparameters,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    if (event.target.id === "datumToChannelOne") {
      this.setState({
        manufacturerparameters: {
          ...this.state.manufacturerparameters,
          datumToChannelOne: event.target.valueAsNumber,
        },
      }, () => {
        this.props.onChange(this.state.manufacturerparameters);
      });
    } else if (event.target.id === "datumToChannelTwo") {
      this.setState({
        manufacturerparameters: {
          ...this.state.manufacturerparameters,
          datumToChannelTwo: event.target.valueAsNumber,
        },
      }, () => {
        this.props.onChange(this.state.manufacturerparameters);
      });
    } else if (event.target.id === "datumToChannelThree") {
        this.setState({
          manufacturerparameters: {
            ...this.state.manufacturerparameters,
            datumToChannelThree: event.target.valueAsNumber,
          },
        }, () => {
          this.props.onChange(this.state.manufacturerparameters);
        });
      }
  }
  render() {
    return (
      <div>
        <DialogTitle>{this.props.title}</DialogTitle>
        <DialogContent style={{paddingTop: '15px'}}>
            {(this.props.labels[0] !== "") ?
            <TextField 
              id="datumToChannelOne"
              outlined
              type="number"
              style={{width: '100%'}}
              label={this.props.labels[0]}
              value={this.state.manufacturerparameters.datumToChannelOne}
              onChange={(event) => this.handleChange(event)}/> : <></>
            }
            {(this.props.labels[0] !== "") && (this.props.labels[1] !== "") ?
            <><br/><br/></> : <></>}
            {(this.props.labels[1] !== "") ?
            <TextField
              id="datumToChannelTwo"
              outlined
              type="number"
              style={{width: '100%'}}
              label={this.props.labels[1]}
              value={this.state.manufacturerparameters.datumToChannelTwo}
              onChange={(event) => this.handleChange(event)}/> : <></>}
            {(this.props.labels[2] !== "") ?
            <>
            <br/>
            <br/>
            <TextField
              id="datumToChannelThree"
              outlined
              type="number"
              style={{width: '100%'}}
              label={this.props.labels[2]}
              value={this.state.manufacturerparameters.datumToChannelThree}
              onChange={(event) => this.handleChange(event)}/>
            </> : <></>}
        </DialogContent>
      </div>
    );
  };
};

export default DatumToChannel;