import React from "react";
import { TextField } from '@rmwc/textfield';
import {
  DialogTitle,
  DialogContent
} from '@rmwc/dialog';

import '@material/dialog/dist/mdc.dialog.css';
import '@material/textfield/dist/mdc.textfield.css';
import '@material/floating-label/dist/mdc.floating-label.css';
import '@material/notched-outline/dist/mdc.notched-outline.css';
import '@material/line-ripple/dist/mdc.line-ripple.css';

class DatumToMotion extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      manufacturerparameters: props.manufacturerparameters,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
      this.setState({
        manufacturerparameters: {
          ...this.state.manufacturerparameters,
          datumToMotionSensor: event.target.valueAsNumber,
        },
    }, () => {
        this.props.onChange(this.state.manufacturerparameters);
    });
  }
  render() {
    return (
      <div>
        <DialogTitle>Datum to motion sensor distance (mm)</DialogTitle>
        <DialogContent style={{paddingTop: '15px'}}>
            <TextField
              type="number"
              outlined
              style={{width: '100%'}}
              label="Datum to motion sensor"
              value={this.state.manufacturerparameters.datumToMotionSensor}
              onChange={(event) => this.handleChange(event)}/>
        </DialogContent>
      </div>
    );
  };
};

export default DatumToMotion;