import {connect} from "react-redux";
import {toggleFeature, readFeature, writeFeature} from "../actions/misc";
import Calibration from "../components/Calibration/Calibration";

const mapStateToProps = ({misc}) => {
  return ({
    firmware: misc.firmware.reading.firmware,
    modelnum: misc.modelnum.reading,
    strain: misc.strain,
    orientation: misc.orientation,
    zerooffsets: misc.zerooffsets.reading,
    scalefactors: misc.scalefactors.reading,
    manufacturerparameters: misc.manufacturerparameters.reading,
  });
};

const mapDispatchToProps = (dispatch) => ({
  enableStrain: () => {
    dispatch(toggleFeature("strain", "on"));
  },
  enableOrientation: () => {
    dispatch(toggleFeature("orientation", "on"));
  },
  disableStrain: () => {
    dispatch(toggleFeature("strain", "off"));
  },
  disableOrientation: () => {
    dispatch(toggleFeature("orientation", "off"));
  },
  readManufacturerParams: () => {
    dispatch(readFeature("manufacturerparameters"));
  },
  readZeroOffsets: () => {
    dispatch(readFeature("zerooffsets"));
  },
  writeZeroOffsets: (params) => {
    dispatch(writeFeature("zerooffsets", params));
  },
  readScaleFactors: () => {
    dispatch(readFeature("scalefactors"));
  },
  writeScaleFactors: (params) => {
    dispatch(writeFeature("scalefactors", params));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Calibration);
