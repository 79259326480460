import React from "react";

import {
  DialogTitle,
  DialogContent
} from '@rmwc/dialog';

import { Select } from '@rmwc/select';

import '@material/dialog/dist/mdc.dialog.css';
import '@material/floating-label/dist/mdc.floating-label.css';
import '@material/notched-outline/dist/mdc.notched-outline.css';
import '@material/line-ripple/dist/mdc.line-ripple.css';
import '@material/select/dist/mdc.select.css';
//import "./styles.css";

class ShaftType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modelnum: props.modelnum,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({
      modelnum: {
          ...this.state.modelnum,
          major: event.target.value,
      },
    }, () => {
      this.props.onChange(this.state.modelnum);
    });
  }

  render() {
    return (
      <div>
        <DialogTitle>Shaft type</DialogTitle>
        <DialogContent style={{paddingTop: '15px'}}>
          <Select
            id="major"
            outlined
            className="full_width"
            value={this.state.modelnum.major}
            onChange={(event) => this.handleChange(event)}
            label="Shaft type"
            placeholder="Unknown"
            options={[
              {
                label: 'Generation 2.1',
                options: [
                  {
                    label: 'Dragon Boat',
                    value: '1'
                  },
                  {
                    label: 'High-Kneel Canoe',
                    value: '2',
                  },
                  {
                    label: 'Kayak (Left Shaft)',
                    value: '3',
                  },
                  {
                    label: 'Kayak (Right Shaft)',
                    value: '4',
                  },
                  {
                    label: 'Outrigger Canoe',
                    value: '5',
                  }
                ]
              },
              {
                label: 'Generation 3.x',
                options: [
                  /*{
                    label: 'Dragon Boat',
                    value: '6',
                  },*/
                  /*{
                    label: 'High Kneel Canoe',
                    value: '7',
                  },*/
                  {
                    label: 'Kayak',
                    value: '8',
                  },
                  {
                    label: 'Gen. 3.0/3.1 - Canoe / Dragon Boat / SUP', //used to be just outrigger canoe
                    value: '9',
                  },
                  /*{
                    label: 'Slalom Canoe',
                    value: '10',
                  },*/
                  /*{
                    label: 'SUP',
                    value: '11',
                  }*/
                  {
                    label: 'Gen. 3.2 - Canoe / Dragon Boat / SUP', //used to be just outrigger canoe
                    value: '12',
                  }
                ]
              }
            ]}
          />
        </DialogContent>
      </div>
    );
  }
}

export default ShaftType;
