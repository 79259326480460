import React from "react";

import {
  DialogTitle,
  DialogContent
} from '@rmwc/dialog';
import { Checkbox } from '@rmwc/checkbox';

import '@material/checkbox/dist/mdc.checkbox.css';
import '@material/form-field/dist/mdc.form-field.css';
import '@material/dialog/dist/mdc.dialog.css';
import '@material/floating-label/dist/mdc.floating-label.css';
import '@material/notched-outline/dist/mdc.notched-outline.css';
import '@material/line-ripple/dist/mdc.line-ripple.css';
import "./styles.css";

class RecordingMessages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: props.messages,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  retrieveBitmask(id) {
    switch (id){
      case "diagnostic_data":
        return 1;
      case "strain_data":
        return 2;
      case "shaft_angle":
        return 4;
      case "blade_velocity":
        return 8;
      case "blade_accel":
        return 16;
      case "hand_force":
        return 32;
      case "hand_power":
        return 64;
      case "blade_force":
        return 128;
      case "static_force":
        return 256;
      default:
        return 0;
    }
  }

  handleChange(event) {
    let message = this.state.messages + ((event.target.checked === true ? (1) : (-1))*this.retrieveBitmask(event.target.id));
    message = message & 0xFF61;
    this.setState({
      messages: message
    }, () => {
      this.props.onChange(this.state.messages);
    });
  }

  render() {
    return (
      <div>
        <DialogTitle>Data messages</DialogTitle>
        <DialogContent>
          <Checkbox
            id="diagnostic_data"
            checked={(this.state.messages & 1)}
            onChange={(event) => this.handleChange(event)}>
            Diagnostic Data
          </Checkbox><br/>
          {/*<Checkbox
            id="strain_data"
            checked={(this.state.messages & 2)}
            onChange={(event) => this.handleChange(event)}>
            Strain Data
          </Checkbox><br/>*/}
          {/*<Checkbox
            id="shaft_angle"
            checked={(this.state.messages & 4)}
            onChange={(event) => this.handleChange(event)}>
            Shaft Angle
          </Checkbox><br/>
          <Checkbox
            id="blade_velocity"
            checked={(this.state.messages & 8)}
            onChange={(event) => this.handleChange(event)}>
            Blade Velocity
          </Checkbox><br/>
          <Checkbox
            id="blade_accel"
            checked={(this.state.messages & 16)}
            onChange={(event) => this.handleChange(event)}>
            Blade Accel
          </Checkbox><br/>*/}
          <Checkbox
            id="hand_force"
            checked={(this.state.messages & 32)}
            onChange={(event) => this.handleChange(event)}>
            Hand Force
          </Checkbox><br/>
          <Checkbox
            id="hand_power"
            checked={(this.state.messages & 64)}
            onChange={(event) => this.handleChange(event)}>
            Hand Power
          </Checkbox><br/>
          {/*<Checkbox
            id="blade_force"
            checked={(this.state.messages & 128)}
            onChange={(event) => this.handleChange(event)}>
            Blade Force
          </Checkbox>*/}
          <Checkbox
            id="static_force"
            checked={(this.state.messages & 256)}
            onChange={(event) => this.handleChange(event)}>
            Force Verification
          </Checkbox>
        </DialogContent>
      </div>
    );
  }
}

export default RecordingMessages;
