import React from "react";

import {
  DialogTitle,
  DialogContent
} from '@rmwc/dialog';

import { TextField } from '@rmwc/textfield';

import '@material/dialog/dist/mdc.dialog.css';
import '@material/textfield/dist/mdc.textfield.css';
import '@material/floating-label/dist/mdc.floating-label.css';
import '@material/notched-outline/dist/mdc.notched-outline.css';
import '@material/line-ripple/dist/mdc.line-ripple.css';

//const MODEL_NUM_MAJOR_UNKNOWN = 0;
const MODEL_NUM_MAJOR_GEN_2_DRAGON_BOAT = 1;
const MODEL_NUM_MAJOR_GEN_2_HIGH_KNEEL = 2;
//const MODEL_NUM_MAJOR_GEN_2_KAYAK_LEFT = 3;
const MODEL_NUM_MAJOR_GEN_2_KAYAK_RIGHT = 4;
const MODEL_NUM_MAJOR_GEN_2_OUTRIGGER = 5;
const MODEL_NUM_MAJOR_GEN_3_DRAGON_BOAT = 6;
const MODEL_NUM_MAJOR_GEN_3_HIGH_KNEEL = 7;
//const MODEL_NUM_MAJOR_GEN_3_KAYAK = 8;
const MODEL_NUM_MAJOR_GEN_3_OUTRIGGER = 9;
const MODEL_NUM_MAJOR_GEN_3_SLALOM_CANOE = 10;
const MODEL_NUM_MAJOR_GEN_3_SUP = 11;

class ScaleFactor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      scaleFactors: props.scaleFactors,
    };
  }

  handleChange(event) {

    let temp = this.state.scaleFactors;

    temp[event.target.id] = event.target.valueAsNumber;

    this.setState({
      scaleFactors: temp
    }, () => {
      this.props.onChange(this.state.scaleFactors);
    });
  }
  render() {
    return (
      <div>
        <DialogTitle>Scale Factor</DialogTitle>
        <DialogContent style={{paddingTop: '15px'}}>
          {((this.props.modelnum.major !== MODEL_NUM_MAJOR_GEN_2_DRAGON_BOAT) && (this.props.modelnum.major !== MODEL_NUM_MAJOR_GEN_2_OUTRIGGER)) ?
          <>
          <TextField
            id="0"
            type="number"
            label={this.props.labels[0]}
            value={this.state.scaleFactors[0]}
            outlined
            style={{width: '100%'}}
            onChange={(event) => this.handleChange(event)}/>
          </> : <></>}
          <>
          <br/>
          <br/>
          <TextField
            id="1"
            type="number"
            label={this.props.labels[1]}
            value={this.state.scaleFactors[1]}
            outlined
            style={{width: '100%'}}
            onChange={(event) => this.handleChange(event)}/>
          </>
          {((this.props.modelnum.major === MODEL_NUM_MAJOR_GEN_2_KAYAK_RIGHT) ||
            (this.props.modelnum.major === MODEL_NUM_MAJOR_GEN_2_HIGH_KNEEL)) ?
          <>
          <br/>
          <br/>
          <TextField
            id="2"
            type="number"
            label={this.props.labels[2]}
            value={this.state.scaleFactors[2]}
            outlined
            style={{width: '100%'}}
            onChange={(event) => this.handleChange(event)}/></> : <></>}
        </DialogContent>
      </div>
    );
  };
};

export default ScaleFactor;