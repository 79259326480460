import React from "react";
import PropTypes from "prop-types";
import add from "../../assets/add.svg";
import clear from "../../assets/clear.svg";
import Thingy from "power-meter-web-bluetooth";
import {withRouter} from "react-router-dom";

import {
  TopAppBarActionItem
} from '@rmwc/top-app-bar';

import '@material/top-app-bar/dist/mdc.top-app-bar.css';

class ConnectButton extends React.Component {
  constructor(props) {
    super(props);
    this.start = this.start.bind(this);
  }

  async start() {
    if (!this.props.connected) {
      try {
        //let dfu = false;//OGL
        //if (this.props.location.pathname === "/update-firmware") {//OGL
        //  dfu = true;
        //}//OGL
        const thingy = new Thingy({logEnabled: false});
        window.thingy = thingy;
        const connected = await window.thingy.connect();
        if (connected) {
          this.props.onConnectionEvent(true);
        }
      } catch (e) {
        console.log(e);
        this.props.notifyError(e);
      }
    } else {
      this.props.onConnectionEvent(false);
      this.props.disconnect();
    }
  }

  render() {
    return (
      <TopAppBarActionItem
        style={{padding: "12px"}} 
        aria-label="Connect"
        alt="Connect"
        icon={!this.props.connected ? add : clear}
        onClick={(e) =>{
          this.start();
        }}
      >
      </TopAppBarActionItem>
    );
  }
}

ConnectButton.propTypes = {
  connected: PropTypes.bool,
  disconnect: PropTypes.func.isRequired,
  onConnectionEvent: PropTypes.func.isRequired,
  notifyError: PropTypes.func,
};

export default withRouter(ConnectButton);
