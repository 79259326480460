import {connect} from "react-redux";
import {readFeature, writeFeature, triggerDeepSleep} from "../actions/misc";
import Configuration from "../components/Basic/Configuration";

const mapStateToProps = ({misc}) => {
  return ({
    firmware: misc.firmware.reading.firmware,
    firmwareRemote: misc.telemetryfirmware.reading.firmware,
    name: misc.name.reading,
    modelnum: misc.modelnum.reading,
    bladetwist: misc.bladetwist.reading,
    distanceparameters: misc.distanceparameters.reading,
    bladeparameters: misc.bladeparameters.reading
  });
};

const mapDispatchToProps = (dispatch) => ({
  readName: () => {
    dispatch(readFeature("name"));
  },
  writeName: (name) => {
    dispatch(writeFeature("name", name));
  },
  readFirmwareRemote: () => {
    dispatch(readFeature("telemetryfirmware"));
  },
  readDistanceParams: () => {
    dispatch(readFeature("distanceparameters"));
  },
  writeDistanceParams: (params) => {
    dispatch(writeFeature("distanceparameters", params));
  },
  readBladeParams: () => {
    dispatch(readFeature("bladeparameters"));
  },
  writeBladeParams: (params) => {
    dispatch(writeFeature("bladeparameters", params));
  },
  readBladeTwist: () => {
    dispatch(readFeature("bladetwist"));
  },
  writeBladeTwist: (params) => {
    dispatch(writeFeature("bladetwist", params));
  },
  triggerDeepSleep: () => {
    dispatch(triggerDeepSleep());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Configuration);
