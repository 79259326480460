import React from "react";
import PropTypes from "prop-types";
import {Card, CardStandardCodeView} from "../Common/Common";
import {CardChartView, CardMetricsView} from "./MetricsCards";
import "./styles.css";

class Metrics extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      forcepeak: props.forcepeak,
      forceaverage: props.forceaverage,
      forceeffectiveaverage: props.forceeffectiveaverage,
      forceeffectivepercent: props.forceeffectivepercent,
      impulse: props.impulse,
      impulseeffective: props.impulseeffective,
    };

    this.toggleForcePeak = props.toggleForcePeak;
    this.toggleForceAverage = props.toggleForceAverage;
    this.toggleForceEffectiveAverage = props.toggleForceEffectiveAverage;
    this.toggleForceEffectivePercent = props.toggleForceEffectivePercent;
    this.toggleImpulse = props.toggleImpulse;
    this.toggleImpulseEffective = props.toggleImpulseEffective;

    this.changeTab = props.changeTab;
  }

  componentWillReceiveProps(np) {
    if (np.forcepeak !== this.state.forcepeak) {
      this.setState({
        forcepeak: np.forcepeak,
      });
    }

    if (np.forceaverage !== this.state.forceaverage) {
      this.setState({
        forceaverage: np.forceaverage,
      });
    }

    if (np.forceeffectiveaverage !== this.state.forceeffectiveaverage) {
      this.setState({
        forceeffectiveaverage: np.forceeffectiveaverage,
      });
    }

    if (np.forceeffectivepercent !== this.state.forceeffectivepercent) {
      this.setState({
        forceeffectivepercent: np.forceeffectivepercent,
      });
    }

    if (np.impulse !== this.state.impulse) {
      this.setState({
        impulse: np.impulse,
      });
    }

    if (np.impulseeffective !== this.state.impulseeffective) {
      this.setState({
        impulseeffective: np.impulseeffective,
      });
    }
  }

  componentDidMount() {
    this.props.toggleAll("on");
  }

  componentWillUnmount() {
    this.props.toggleAll("off");
  }

  render() {
    return (
      <div>
        <Card name="Peak Force" interactionName="forcepeak" changeTab={this.changeTab} toggleFeature={this.toggleForcePeak} tab={this.state.forcepeak.activeTab}>
          {(!this.state.forcepeak.activeTab || this.state.forcepeak.activeTab === "feature") && <CardMetricsView feature={this.state.forcepeak} />}
          {this.state.forcepeak.activeTab === "code" && <CardStandardCodeView code="forcepeak" feature={this.state.forcepeak} featureMode="notify"/>}
        </Card>

        <Card name="Average Force" interactionName="forceaverage" changeTab={this.changeTab} toggleFeature={this.toggleForceAverage} tab={this.state.forceaverage.activeTab}>
          {(!this.state.forceaverage.activeTab || this.state.forceaverage.activeTab === "feature") && <CardChartView feature={this.state.forceaverage} />}
          {this.state.forceaverage.activeTab === "code" && <CardStandardCodeView code="forceaverage" feature={this.state.forceaverage} featureMode="notify"/>}
        </Card>

        <Card name="Effective Force Average" interactionName="forceeffectiveaverage" changeTab={this.changeTab} toggleFeature={this.toggleForceEffectiveAverage} tab={this.state.forceeffectiveaverage.activeTab}>
          {(!this.state.forceeffectiveaverage.activeTab || this.state.forceeffectiveaverage.activeTab === "feature") && <CardChartView feature={this.state.forceeffectiveaverage} />}
          {this.state.forceeffectiveaverage.activeTab === "code" && <CardStandardCodeView code="forceeffectiveaverage" feature={this.state.forceeffectiveaverage} featureMode="notify"/>}
        </Card>

        <Card name="Effective Force Percentage" interactionName="forceeffectivepercent" changeTab={this.changeTab} toggleFeature={this.toggleForceEffectivePercent} tab={this.state.forceeffectivepercent.activeTab}>
          {(!this.state.forceeffectivepercent.activeTab || this.state.forceeffectivepercent.activeTab === "feature") && <CardChartView feature={this.state.forceeffectivepercent} />}
          {this.state.forceeffectivepercent.activeTab === "code" && <CardStandardCodeView code="forceeffectivepercent" feature={this.state.forceeffectivepercent} featureMode="notify"/>}
        </Card>

        <Card name="Impulse" interactionName="impulse" changeTab={this.changeTab} toggleFeature={this.toggleImpulse} tab={this.state.impulse.activeTab}>
          {(!this.state.impulse.activeTab || this.state.impulse.activeTab === "feature") && <CardChartView feature={this.state.impulse} />}
          {this.state.impulse.activeTab === "code" && <CardStandardCodeView code="impulse" feature={this.state.impulse} featureMode="notify"/>}
        </Card>

        <Card name="Effective Impulse" interactionName="impulseeffective" changeTab={this.changeTab} toggleFeature={this.toggleImpulseEffective} tab={this.state.impulseeffective.activeTab}>
          {(!this.state.impulseeffective.activeTab || this.state.impulseeffective.activeTab === "feature") && <CardChartView feature={this.state.impulseeffective} />}
          {this.state.impulseeffective.activeTab === "code" && <CardStandardCodeView code="impulseeffective" feature={this.state.impulseeffective} featureMode="notify"/>}
        </Card>
      </div>
    );
  }
}

Metrics.propTypes = {
    forcepeak: PropTypes.object,
    forceaverage: PropTypes.object,
    forceeffectiveaverage: PropTypes.object,
    forceeffectivepercent: PropTypes.object,
    impulse: PropTypes.object,
    impulseeffective: PropTypes.object,
    changeTab: PropTypes.func,
    toggleForcePeak: PropTypes.func,
    toggleForceAverage: PropTypes.func,
    toggleForceEffectiveAverage: PropTypes.func,
    toggleForceEffectivePercent: PropTypes.func,
    toggleImpulse: PropTypes.func,
    toggleImpulseEffective: PropTypes.func,
    toggleAll: PropTypes.func,
};

export default Metrics;
