import React from "react";
import { Select } from '@rmwc/select';
import {
  DialogTitle,
  DialogContent
} from '@rmwc/dialog';

import '@material/select/dist/mdc.select.css';
import '@material/floating-label/dist/mdc.floating-label.css';
import '@material/notched-outline/dist/mdc.notched-outline.css';
import '@material/line-ripple/dist/mdc.line-ripple.css';
import '@material/dialog/dist/mdc.dialog.css';

class RecordingFrequency extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      frequency: props.frequency,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({
      frequency: event.target.value
    }, () => {
      this.props.onChange(this.state.frequency);
    });
  }

  render() {
    return (
      <div>
        <DialogTitle>Sample rate (Hz)</DialogTitle>
        <DialogContent style={{paddingTop: '15px'}}>
          <Select
            outlined
            className="full_width"
            value={this.state.frequency}
            onChange={(event) => this.handleChange(event)}
            label="Frequency"
            placeholder=""
            options={[
              {
                label: '100',
                value: '100',
              }
            ]}
          />
          {/*<TextField type="number" style={{width: '100%'}} label="Frequency" value={this.state.frequency} onChange={(event) => this.handleChange(event)}/>*/}
        </DialogContent>
      </div>
    );
  };
};

export default RecordingFrequency;