import {connect} from "react-redux";
import {readFeature, writeFeature} from "../actions/misc";
import AdvancedConfiguration from "../components/Advanced/Configuration";

const mapStateToProps = ({misc}) => {
  return ({
    manufacturerparameters: misc.manufacturerparameters.reading,
    remoteaddress: misc.remoteaddress.reading,
    modelnum: misc.modelnum.reading,
    serialnum: misc.serialnum.reading
  });
};

const mapDispatchToProps = (dispatch) => ({
  readManufacturerParams: () => {
    dispatch(readFeature("manufacturerparameters"));
  },
  writeManufacturerParams: (params) => {
    dispatch(writeFeature("manufacturerparameters", params));
  },
  readRemoteAddress: () => {
    dispatch(readFeature("remoteaddress"));
  },
  writeRemoteAddress: (address) => {
    dispatch(writeFeature("remoteaddress", address));
  },
  readSerialNum: () => {
    dispatch(readFeature("serialnum"));
  },
  writeSerialNum: (serialnum) => {
    dispatch(writeFeature("serialnum", serialnum));
  },
  readModelNum: () => {
    dispatch(readFeature("modelnum"));
  },
  writeModelNum: (modelnum) => {
    dispatch(writeFeature("modelnum", modelnum));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdvancedConfiguration);
