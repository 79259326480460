import {connect} from "react-redux";
import {readFeature, writeFeature, toggleFeature} from "../actions/misc";
import HighSpeedData from "../components/HighSpeedData/HighSpeedData";

const mapStateToProps = ({misc}) => {
  return ({
    statistics: misc.recordstatistics,
    configuration: misc.recordconfiguration.reading
  });
};

const mapDispatchToProps = (dispatch) => ({
  enableStatistics: () => {
    dispatch(toggleFeature("recordstatistics", "on"));
  },
  disableStatistics: () => {
    dispatch(toggleFeature("recordstatistics", "off"));
  },
  readRecordConfig: () => {
    dispatch(readFeature("recordconfiguration"));
  },
  writeRecordConfig: (params) => {
    const parsedParams = {
      recordingLimitSecs: params.recordingLimitSecs,
      recordingFrequency: params.recordingFrequency,
      recordingMessages: params.recordingMessages,
      recordingState: ((params.recordingState === 1) ? true : false),
    };
    dispatch(writeFeature("recordconfiguration", parsedParams));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HighSpeedData);
