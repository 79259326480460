import React from "react";
import { TextField } from '@rmwc/textfield';
import {
  DialogTitle,
  DialogContent
} from '@rmwc/dialog';

import '@material/dialog/dist/mdc.dialog.css';
import '@material/textfield/dist/mdc.textfield.css';
import '@material/floating-label/dist/mdc.floating-label.css';
import '@material/notched-outline/dist/mdc.notched-outline.css';
import '@material/line-ripple/dist/mdc.line-ripple.css';

class RemoteAddress extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      remoteaddress: props.remoteaddress,
    };

    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(event) {
    this.setState({
      remoteaddress: {
        ...this.state.remoteaddress,
        address: event.target.value,
      },
    }, () => {
      this.props.onChange(this.state.remoteaddress);
    });
  }
  render() {
    return (
      <div>
        <DialogTitle>Remote address</DialogTitle>
        <DialogContent style={{paddingTop: '15px'}}>
          <TextField
            style={{width: '100%'}}
            outlined
            label="Address"
            pattern="[0-9A-F]{12}"
            value={this.state.remoteaddress.address}
            onChange={(event) => this.handleChange(event)}/>
        </DialogContent>
      </div>
    );
  };
};

export default RemoteAddress;