import React, {Component} from "react";
import DashboardContainer from "./containers/DashboardContainer";

class App extends Component {
  render() {
    return (
      <div>
        <DashboardContainer />
        </div>
    );
  }
}

export default App;
