import React from "react";
import Name from "./Name";
import bladelist from "../../assets/blade_list.js";
import AthleteSettings from "./AthleteSettings";
import DatumSettings from "./DatumSettings";
import PaddleLength from "./PaddleLength";
import BladeModel from "./BladeModel";
import BladeTwist from "./BladeTwist";
import ShaftHandleOffset from "./ShaftHandleOffset";
import DeepSleep from "./DeepSleep";
import "./styles.css";

import _ from "underscore";

import { Grid, GridCell } from '@rmwc/grid';

import {
  List,
  ListItem,
  ListItemText,
  ListItemPrimaryText,
  ListItemSecondaryText,
  ListGroup,
  ListDivider
} from '@rmwc/list';

import {
  Dialog,
  DialogActions,
  DialogButton
} from '@rmwc/dialog';

import '@material/layout-grid/dist/mdc.layout-grid.css';
import '@material/list/dist/mdc.list.css';
import '@material/dialog/dist/mdc.dialog.css';
import '@material/button/dist/mdc.button.css';

//const MODEL_NUM_MAJOR_UNKNOWN = 0;
const MODEL_NUM_MAJOR_GEN_2_DRAGON_BOAT = 1;
const MODEL_NUM_MAJOR_GEN_2_HIGH_KNEEL = 2;
//const MODEL_NUM_MAJOR_GEN_2_KAYAK_LEFT = 3;
const MODEL_NUM_MAJOR_GEN_2_KAYAK_RIGHT = 4;
const MODEL_NUM_MAJOR_GEN_2_OUTRIGGER = 5;
//const MODEL_NUM_MAJOR_GEN_3_DRAGON_BOAT = 6;
const MODEL_NUM_MAJOR_GEN_3_HIGH_KNEEL = 7;
const MODEL_NUM_MAJOR_GEN_3_KAYAK = 8;
const MODEL_NUM_MAJOR_GEN_3_OUTRIGGER = 9;
const MODEL_NUM_MAJOR_GEN_3_SLALOM_CANOE = 10;
const MODEL_NUM_MAJOR_GEN_3_SUP = 11;
const MODEL_NUM_MAJOR_GEN_3_CANOE = 12;

class Configuration extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dialogOpen: false,
    };
  }

  componentDidMount() {
    this.props.readName();
    this.props.readFirmwareRemote();
    this.props.readDistanceParams();
    this.props.readBladeParams();
    this.props.readBladeTwist();
  }

  handleNameChange(name) {
    this.setState({
      name: {
        name: name
      }
    });
  }

  handleAthleteChange(params) {
    this.setState({
      distanceparameters: {
        ...this.props.distanceparameters,
        tipToHandA: params.tipToHandA,
        tipToHandB: params.tipToHandB
      }
    });
  }

  handlePaddleLengthChange(params) {
    this.setState({
      distanceparameters: {
        ...this.props.distanceparameters,
        tipToTip: params.tipToTip
      }
    });
  }

  handleShaftHandleOffsetChange(params) {
    this.setState({
      distanceparameters: {
        ...this.props.distanceparameters,
        shaftToHandleOffset: params.shaftToHandleOffset
      }
    });
  }

  handleDatumChange(params) {
    this.setState({
      distanceparameters: {
        ...this.props.distanceparameters,
        tipToDatumA: params.tipToDatumA,
        tipToDatumB: params.tipToDatumB
      }
    });
  }

  handleBladeModelChange(bladeparameters) {
    this.setState({
      bladeparameters,
    });
  }

  handleBladeTwistChange(bladetwist) {
    this.setState({
      bladetwist,
    });
  }

  // -----

  handleNameSubmit() {
    if ((this.state.name !== undefined) && (this.state.name.name !== undefined)){
      this.props.writeName(this.state.name.name);
    }
  }

  handleAthleteSubmit() {
    if ((this.state.distanceparameters !== undefined) && (this.state.distanceparameters.tipToHandA !== undefined) && (this.state.distanceparameters.tipToHandB !== undefined)) {
      this.setState({
        distanceparameters: {
          ...this.props.distanceparameters,
          tipToHandA: this.state.distanceparameters.tipToHandA,
          tipToHandB: this.state.distanceparameters.tipToHandB
        },
      }, () => {
        this.props.writeDistanceParams(this.state.distanceparameters);
      });
    }
  }

  handleDatumSubmit() {
    if ((this.state.distanceparameters !== undefined) && (this.state.distanceparameters.tipToDatumA !== undefined) && (this.state.distanceparameters.tipToDatumB !== undefined)) {
      this.setState({
        distanceparameters: {
          ...this.props.distanceparameters,
          tipToDatumA: this.state.distanceparameters.tipToDatumA,
          tipToDatumB: this.state.distanceparameters.tipToDatumB
        },
      }, () => {
        this.props.writeDistanceParams(this.state.distanceparameters);
      });
    }
  }

  handlePaddleLengthSubmit() {
    if ((this.state.distanceparameters !== undefined) && (this.state.distanceparameters.tipToTip !== undefined)) {
      this.setState({
        distanceparameters: {
          ...this.props.distanceparameters,
          tipToTip: this.state.distanceparameters.tipToTip
        },
      }, () => {
        this.props.writeDistanceParams(this.state.distanceparameters);
      });
    }
  }

  handleShaftHandleOffsetSubmit() {
    console.log(this.props.distanceparameters);
    if ((this.state.distanceparameters !== undefined) && (this.state.distanceparameters.shaftToHandleOffset !== undefined)) {
      this.setState({
        distanceparameters: {
          ...this.props.distanceparameters,
          shaftToHandleOffset: this.state.distanceparameters.shaftToHandleOffset
        },
      }, () => {
        this.props.writeDistanceParams(this.state.distanceparameters);
      });
    }
  }

  handleBladeModelSubmit() {
    if (this.state.bladeparameters !== undefined) {
      console.log(this.state.bladeparameters)
      this.props.writeBladeParams(this.state.bladeparameters);
    }
  }

  handleBladeTwistSubmit() {
    if (this.state.bladetwist !== undefined) {
      this.props.writeBladeTwist(this.state.bladetwist);
    }
  }

  handleDeepSleepSubmit() {
    this.props.triggerDeepSleep();
  }

  openPopup(content) {
    let c;
    let oc;
    switch (content) {
    case "name":
      c = <Name name={this.props.name.name} onChange={this.handleNameChange.bind(this)}/>;
      oc = this.handleNameSubmit.bind(this);
      break;

    case "athlete":
      if ((this.props.modelnum.major === MODEL_NUM_MAJOR_GEN_2_KAYAK_RIGHT) || (this.props.modelnum.major === MODEL_NUM_MAJOR_GEN_3_KAYAK)){
        c = <AthleteSettings
              distanceparameters={this.props.distanceparameters}
              labelA="Right tip to right hand"
              labelB="Left tip to left hand"
              onChange={this.handleAthleteChange.bind(this)}/>
      } else {
        c = <AthleteSettings
              distanceparameters={this.props.distanceparameters}
              labelA="Blade tip to top hand"
              labelB="Blade tip to bottom hand"
              onChange={this.handleAthleteChange.bind(this)}/>
      }
      oc = this.handleAthleteSubmit.bind(this);
      break;

    case "datum":
      let title;
      let labels;

      switch (this.props.modelnum.major)
      {
        case MODEL_NUM_MAJOR_GEN_2_KAYAK_RIGHT:
          title = "Blade tip to datum distances (mm)";
          labels = ['Right tip to right datum', 'Left tip to left datum'];
          break;

        case MODEL_NUM_MAJOR_GEN_3_KAYAK:
          title = "Left blade tip to LED distance (mm)";
          labels = ["Left blade tip to LED"];
          break;

        case MODEL_NUM_MAJOR_GEN_2_DRAGON_BOAT:
        case MODEL_NUM_MAJOR_GEN_2_HIGH_KNEEL:
        case MODEL_NUM_MAJOR_GEN_2_OUTRIGGER:
          title = "Blade tip to datum distance (mm)"
          labels = ["Blade tip to datum"]
          break;

        default:
          title = "Blade tip to LED distance (mm)"
          labels = ["Blade tip to LED"]
          break;
      }

      c = <DatumSettings
            distanceparameters={this.props.distanceparameters}
            title={title}
            labels={labels}
            onChange={this.handleDatumChange.bind(this)}/>;

      oc = this.handleDatumSubmit.bind(this);
      break;

    case "paddlelength":
      c = <PaddleLength distanceparameters={this.props.distanceparameters} onChange={this.handlePaddleLengthChange.bind(this)}/>;
      oc = this.handlePaddleLengthSubmit.bind(this);
      break;

    case "bladetwist":
      c = <BladeTwist bladetwist={this.props.bladetwist} onChange={this.handleBladeTwistChange.bind(this)}/>;
      oc = this.handleBladeTwistSubmit.bind(this);
      break;

    case "blademodel":
      c = <BladeModel modelnum={this.props.modelnum.major} bladeparameters={this.props.bladeparameters} bladelist={bladelist} onChange={this.handleBladeModelChange.bind(this)}/>;
      oc = this.handleBladeModelSubmit.bind(this);
      break;

    case "shafthandleoffset":
      c = <ShaftHandleOffset distanceparameters={this.props.distanceparameters} onChange={this.handleShaftHandleOffsetChange.bind(this)}/>
      oc = this.handleShaftHandleOffsetSubmit.bind(this);
      break;

    case "deepsleep":
      c = <DeepSleep />;
      oc = this.handleDeepSleepSubmit.bind(this);
      break;

    default:
      c = null;
      oc = () => {};
      break;
    }

    this.setState((ps) => ({
      ...ps,
      content: c,
      dialogOpen: true,
      onConfirm: oc,
    }));
  }

  closePopup() {
    this.setState({
      dialogOpen: false,
    });
  }

  render() {
    return (
      <div>
      {/* Standard Grid. For convenience, GridInner is added for you */}
        <Grid>
          <GridCell span="4"></GridCell>
          <GridCell span="4">
          <ListGroup>
            <List twoLine>
              <ListItem onClick={() => this.openPopup("name")}>
                <ListItemText>
                  <ListItemPrimaryText>Power meter name</ListItemPrimaryText>
                  <ListItemSecondaryText>{this.props.name.name}</ListItemSecondaryText>
                </ListItemText>
              </ListItem>
              <ListDivider/>
              <ListItem>
                <ListItemText>
                  <ListItemPrimaryText>Firmware version</ListItemPrimaryText>
                  {(this.props.modelnum.major === MODEL_NUM_MAJOR_GEN_2_KAYAK_RIGHT) ?
                  (this.props.firmwareRemote !== "") ?
                  <ListItemSecondaryText>Left: {(this.props.firmwareRemote !== "v255.255.255") ? this.props.firmwareRemote : 'Unknown'} / Right: {this.props.firmware}</ListItemSecondaryText> :
                  <ListItemSecondaryText>{this.props.firmware}</ListItemSecondaryText> :
                  <ListItemSecondaryText>{this.props.firmware}</ListItemSecondaryText> 
                  }
                </ListItemText>
              </ListItem>
              <ListDivider/>
              <ListItem onClick={() => this.openPopup("athlete")}>
                <ListItemText>
                  {((this.props.modelnum.major === MODEL_NUM_MAJOR_GEN_2_KAYAK_RIGHT) || (this.props.modelnum.major === MODEL_NUM_MAJOR_GEN_3_KAYAK)) ? <>
                  <ListItemPrimaryText>Blade tip to hand distances</ListItemPrimaryText>
                  <ListItemSecondaryText>Left: {this.props.distanceparameters.tipToHandB} mm / Right: {this.props.distanceparameters.tipToHandA} mm</ListItemSecondaryText></> : <>
                  <ListItemPrimaryText>Blade tip to hand distances</ListItemPrimaryText>
                  <ListItemSecondaryText>Bottom: {this.props.distanceparameters.tipToHandB} mm / Top: {this.props.distanceparameters.tipToHandA} mm</ListItemSecondaryText></>
                  }
                </ListItemText>
              </ListItem>
              <ListDivider/>
              {((this.props.modelnum.major === MODEL_NUM_MAJOR_GEN_2_KAYAK_RIGHT) || (this.props.modelnum.major === MODEL_NUM_MAJOR_GEN_3_KAYAK)) ?
              <>
              <ListItem onClick={() => this.openPopup("paddlelength")}>
                <ListItemText>
                  <ListItemPrimaryText>Blade tip to blade tip distance</ListItemPrimaryText>
                  <ListItemSecondaryText>{this.props.distanceparameters.tipToTip} mm</ListItemSecondaryText>
                </ListItemText>
              </ListItem>
              <ListDivider/>
              <ListItem onClick={() => this.openPopup("bladetwist")}>
                <ListItemText>
                  <ListItemPrimaryText>Blade twist</ListItemPrimaryText>
                  <ListItemSecondaryText>{bladeTwistHand(this.props.bladetwist.hand)} / {this.props.bladetwist.angle}°</ListItemSecondaryText>
                </ListItemText>
              </ListItem>
              <ListDivider/>
              </> : <></>}
              {((this.props.modelnum.major === MODEL_NUM_MAJOR_GEN_2_HIGH_KNEEL) ||
                (this.props.modelnum.major === MODEL_NUM_MAJOR_GEN_2_KAYAK_RIGHT) ||
                (this.props.modelnum.major === MODEL_NUM_MAJOR_GEN_2_OUTRIGGER) ||
                (this.props.modelnum.major === MODEL_NUM_MAJOR_GEN_3_HIGH_KNEEL) ||
                (this.props.modelnum.major === MODEL_NUM_MAJOR_GEN_3_KAYAK) ||
                (this.props.modelnum.major === MODEL_NUM_MAJOR_GEN_3_OUTRIGGER) || 
                (this.props.modelnum.major === MODEL_NUM_MAJOR_GEN_3_SLALOM_CANOE) ||
                (this.props.modelnum.major === MODEL_NUM_MAJOR_GEN_3_SUP) ||
                (this.props.modelnum.major === MODEL_NUM_MAJOR_GEN_3_CANOE)) ? <>
              <ListItem onClick={() => this.openPopup("blademodel")}>
                <ListItemText>
                  <ListItemPrimaryText>Blade type attached to shaft</ListItemPrimaryText>
                  <ListItemSecondaryText>{bladeModel(this.props.bladeparameters.bladeId)}</ListItemSecondaryText>
                </ListItemText>
              </ListItem>
              <ListDivider/>
              </> : <></>
              }
              <ListItem onClick={() => this.openPopup("datum")}>
                <ListItemText>
                {(this.props.modelnum.major === MODEL_NUM_MAJOR_GEN_2_KAYAK_RIGHT) ?
                  <>
                  <ListItemPrimaryText>Blade tip to datum distances</ListItemPrimaryText>
                  <ListItemSecondaryText>Left: {this.props.distanceparameters.tipToDatumB} mm / Right: {this.props.distanceparameters.tipToDatumA} mm</ListItemSecondaryText>
                  </> : 
                  ((this.props.modelnum.major === MODEL_NUM_MAJOR_GEN_2_DRAGON_BOAT) ||
                   (this.props.modelnum.major === MODEL_NUM_MAJOR_GEN_2_HIGH_KNEEL) ||
                   (this.props.modelnum.major === MODEL_NUM_MAJOR_GEN_2_OUTRIGGER)) ?
                  <>
                  <ListItemPrimaryText>Blade tip to datum distance</ListItemPrimaryText>
                  <ListItemSecondaryText>{this.props.distanceparameters.tipToDatumA} mm</ListItemSecondaryText> 
                  </> :
                  <>
                  <ListItemPrimaryText>Blade tip to LED distance</ListItemPrimaryText>
                  <ListItemSecondaryText>{this.props.distanceparameters.tipToDatumA} mm</ListItemSecondaryText> 
                  </> 
                }
                </ListItemText>
              </ListItem>
              <ListDivider/>
              {((this.props.modelnum.major === MODEL_NUM_MAJOR_GEN_2_OUTRIGGER) || (this.props.modelnum.major === MODEL_NUM_MAJOR_GEN_3_OUTRIGGER) || (this.props.modelnum.major === MODEL_NUM_MAJOR_GEN_3_CANOE))? <>
              <ListItem onClick={() => this.openPopup("shafthandleoffset")}>
                <ListItemText>
                  <ListItemPrimaryText>Shaft to handle offset distance</ListItemPrimaryText>
                  <ListItemSecondaryText>{this.props.distanceparameters.shaftToHandleOffset} mm</ListItemSecondaryText>
                </ListItemText>
              </ListItem>
              <ListDivider/></> : <></>}
              <ListItem onClick={() => this.openPopup("deepsleep")}>
                <ListItemText>
                  <ListItemPrimaryText>Deep sleep</ListItemPrimaryText>
                  <ListItemSecondaryText>Place the power meter in to a low power sleep mode</ListItemSecondaryText>
                </ListItemText>
              </ListItem>
              <ListDivider/>
            </List>
            </ListGroup>
          </GridCell>
          <GridCell span="4"></GridCell>
        </Grid>
        <Dialog
          open={this.state.dialogOpen}
          onClose={evt => {
            this.setState({dialogOpen: false})
            if (evt.detail.action === "confirm") {
              this.state.onConfirm()
            }
          }}
        >    
          {this.state.content}
          <DialogActions>
            <DialogButton action="close">Cancel</DialogButton>
            <DialogButton action="confirm" isDefaultAction>Confirm</DialogButton>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const bladeModel = (bladeId) => {
  const blade = _.findWhere(bladelist, {bladeId: bladeId});
  if (blade)
  {
    return `${blade.manufacturer} - ${blade.model} ${blade.size}`;
  }
};

const bladeTwistHand = (value) => {
  switch (value)
  {
    case 0:
      return 'Unknown hand twist';
    case 1:
      return 'Left hand twist';
    case 2:
      return 'Right hand twist';
    default:
      break;
  } 
};

export default Configuration;
