import React from "react";
import { TextField } from '@rmwc/textfield';
import {
  DialogTitle,
  DialogContent
} from '@rmwc/dialog';

import '@material/textfield/dist/mdc.textfield.css';
import '@material/floating-label/dist/mdc.floating-label.css';
import '@material/notched-outline/dist/mdc.notched-outline.css';
import '@material/line-ripple/dist/mdc.line-ripple.css';
import '@material/dialog/dist/mdc.dialog.css';

class RecordingLimit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      limit: props.limit,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({
      limit: event.target.valueAsNumber
    }, () => {
      this.props.onChange(this.state.limit);
    });
  }

  render() {
    return (
      <div>
        <DialogTitle>Limit (seconds)</DialogTitle>
        <DialogContent style={{paddingTop: '15px'}}>
          <TextField
            type="number"
            outlined
            style={{width: '100%'}}
            label="Limit"
            value={this.state.limit}
            onChange={(event) => this.handleChange(event)}/>
        </DialogContent>
      </div>
    );
  };
};

export default RecordingLimit;