import React from "react";
import PropTypes from "prop-types";
import {Route, NavLink, Switch, Redirect} from "react-router-dom";
import {BrowserRouter as Router} from "react-router-dom";
import CalibrationContainer from "../../containers/CalibrationContainer";
import MetricsContainer from "../../containers/MetricsContainer";
import ConnectButton from "./ConnectButton";
import battery_20 from "../../assets/battery_20.svg";
import battery_30 from "../../assets/battery_30.svg";
import battery_50 from "../../assets/battery_50.svg";
import battery_60 from "../../assets/battery_60.svg";
import battery_80 from "../../assets/battery_80.svg";
import battery_90 from "../../assets/battery_90.svg";
import battery_full from "../../assets/battery_full.svg";
import battery_unknown from "../../assets/battery_unknown.svg";
import menu from "../../assets/menu.svg";
import settings from "../../assets/settings.svg";
import timeline from "../../assets/timeline.svg";
import waves from "../../assets/waves.svg";
import no_svg from "../../assets/no_svg.svg";
import ConfigurationContainer from "../../containers/ConfigurationContainer";
import AdvancedContainer from "../../containers/AdvancedContainer";
import HighSpeedDataContainer from "../../containers/HighSpeedDataContainer";

import {
  TopAppBar,
  TopAppBarRow,
  TopAppBarSection,
  TopAppBarNavigationIcon,
  TopAppBarActionItem,
  TopAppBarTitle,
  TopAppBarFixedAdjust
} from '@rmwc/top-app-bar';
import { Snackbar } from '@rmwc/snackbar';
import { CircularProgress } from '@rmwc/circular-progress';
import {
  Drawer,
  DrawerHeader,
  DrawerContent,
  DrawerTitle,
  DrawerSubtitle
} from '@rmwc/drawer';

import {
  List,
  ListItem,
  ListItemGraphic
} from '@rmwc/list';

import '@material/top-app-bar/dist/mdc.top-app-bar.css';
import '@material/snackbar/dist/mdc.snackbar.css';
import '@material/drawer/dist/mdc.drawer.css';
import '@material/list/dist/mdc.list.css';
import '@rmwc/circular-progress/circular-progress.css';
import '@rmwc/icon/icon.css';

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {notification: props.notification, firmware: props.firmware};
    this.onConnectionEvent = this.onConnectionEvent.bind(this);
  }

  componentWillReceiveProps(np) {
    if (np.firmware !== this.state.firmware) {
      this.setState({
        firmware: np.firmware,
      });
    }
    if (np.notification !== this.state.notification) {
      this.setState({
        notification: np.notification,
      });
      if (np.notification) {
        //const type = np.notification.category;
        const message = np.notification.message;
        this.setState({
          snackbarMessage: message,
          snackbarIsOpen: true
        });
        //if (type === "success") {
          //toast.success(message, {
          //  autoClose: 2000,
          //});
        //} else if (type === "info") {
          //toast.info(message);
        //} else if (type === "warning") {
          //toast.warn(message);
        //} else if (type === undefined) {
          //toast.error("undefined error");
        //} else {
          //toast.error(message);
        //}
      }
    }
  }

  onConnectionEvent(state) {
    this.props.onConnectionEvent(state);
    if (state) {
      this.setState({
        snackbarMessage: "Power meter connected",
        snackbarIsOpen: true
      });
      this.props.readName();
      this.props.readModelNum();
      this.props.readFirmware();
      this.props.startErrorNotification();
      this.props.startDisconnectNotification();
      this.props.startWriteNotification();
      this.props.startBatteryNotification();
      this.props.startTelemetryNotification();
    } else {
      this.setState({
        snackbarMessage: "Power meter disconnected",
        snackbarIsOpen: true
      });
    }
  }

  render() {
    let routes = (
      <Switch>
        {/*<Route exact path="/update-firmware" component={DFUContainer} />*/}
      </Switch>
    );
    let battery = no_svg, batteryRemote = no_svg;

    if (this.props.connected) {
      if (this.state.firmware === "" || this.state.firmware === undefined) {
        routes = (
          <CircularProgress size="xlarge" style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
          }}/>
        );
      //} else if (this.state.firmware !== "v0.1.6") {
      //  routes = (
      //    <Grid>
      //      <GridCell span="4"></GridCell>
      //      <GridCell span="4"><UpdateFirmware /></GridCell>
      //      <GridCell span="4"></GridCell>
      //    </Grid>
      //  );
      } else {
        routes = (
          <Switch>
            <Route exact path="/metrics" component={MetricsContainer} />
            <Route exact path="/calibration" component={CalibrationContainer} />
            <Route exact path="/configuration" component={ConfigurationContainer} />
            <Route exact path="/advanced" component={AdvancedContainer} />
            <Route exact path="/high-speed-data" component={HighSpeedDataContainer} />
            <Redirect from="/" to="/configuration" />
          </Switch>
        );
        battery = batteryIcon(this.props.batteryLevel);
        batteryRemote = batteryIcon(this.props.batteryRemoteLevel);
      }
    }

    return (
      <Router>
        <div>
        <TopAppBar fixed={true}>
          <TopAppBarRow>
            <TopAppBarSection alignStart>
              <TopAppBarNavigationIcon style={{padding: "12px"}} icon={menu} onClick={() => this.setState({modalOpen: !this.state.modalOpen})}/>
              <TopAppBarTitle style={{color: "#000"}}>{this.props.name}</TopAppBarTitle>
            </TopAppBarSection>
            <TopAppBarSection alignEnd>
              {(this.props.modelnum.major === 4) ?
              <TopAppBarActionItem
                aria-label="Battery"
                alt="Battery"
                style={{ cursor: "default", padding: "12px" }}
                icon={batteryRemote}
              /> : ""}
              <TopAppBarActionItem
                aria-label="Battery"
                alt="Battery"
                style={{ cursor: "default", padding: "12px" }}
                icon={battery}
              />
              <ConnectButton onConnectionEvent={this.onConnectionEvent} disconnect={this.props.disconnect} notifyError={this.props.notifyError} connected={this.props.connected}/>
            </TopAppBarSection>
          </TopAppBarRow>
        </TopAppBar>
        <TopAppBarFixedAdjust></TopAppBarFixedAdjust>
        <Drawer
          modal
          open={this.state.modalOpen}
          onClose={() => this.setState({modalOpen: false})}
        >
          <DrawerHeader>
            <DrawerTitle>One Giant Leap</DrawerTitle>
            <DrawerSubtitle>Web Bluetooth App (v1.3.1)</DrawerSubtitle>
          </DrawerHeader>
          <DrawerContent>
            <List>
              <NavLink style={{textDecoration: 'none'}} to="/configuration">
                <ListItem onClick={() => this.setState({modalOpen: false})}>
                  <ListItemGraphic icon={settings}/>Configuration
                </ListItem>
              </NavLink>
              <NavLink style={{textDecoration: 'none'}} to="/high-speed-data">
                <ListItem onClick={() => this.setState({modalOpen: false})}>
                  <ListItemGraphic icon={timeline}/>High Speed Data
                </ListItem>
              </NavLink>
              <NavLink style={{textDecoration: 'none'}} to="/calibration">
                <ListItem onClick={() => this.setState({modalOpen: false})}>
                  <ListItemGraphic icon={waves}/>Calibration
                </ListItem>
              </NavLink>
            </List>
          </DrawerContent>
        </Drawer>
        <main>
          {
            routes
          }
        </main>
        <Snackbar
          open={this.state.snackbarIsOpen}
          onClose={evt => this.setState({snackbarIsOpen: false})}
          message={this.state.snackbarMessage}
          /*actionText="Dismiss"
          /*actionHandler={() => {}}*/
          /*timeout={2000}*/
          /*dismissesOnAction={false}*/
        />
      </div>
      </Router>
    );
  }
}

const batteryIcon = (batteryLevel) => {
  if (batteryLevel === -1) {
    return battery_unknown
  } else if (batteryLevel <= 20) {
    return battery_20;
  } else if (batteryLevel <= 30) {
    return battery_30;
  } else if (batteryLevel <= 50) {
    return battery_50;
  } else if (batteryLevel <= 60) {
    return battery_60;
  } else if (batteryLevel <= 80) {
    return battery_80;
  } else if (batteryLevel <= 90) {
    return battery_90;
  } else {
    return battery_full;
  }
};

Dashboard.propTypes = {
  name: PropTypes.string,
  notification: PropTypes.object,
  connected: PropTypes.bool,
  batteryLevel: PropTypes.number,
  batteryRemoteLevel: PropTypes.number,
  onConnectionEvent: PropTypes.func,
  readName: PropTypes.func,
  readModelNum: PropTypes.func,
  readFirmware: PropTypes.func,
  readFirmwareRemote: PropTypes.func,
  startErrorNotification: PropTypes.func,
  startWriteNotification: PropTypes.func,
  startBatteryNotification: PropTypes.func,
  startTelemetryNotification: PropTypes.func,
  startDisconnectNotification: PropTypes.func,
  notifyError: PropTypes.func,
  disconnect: PropTypes.func,
  firmware: PropTypes.string,
  firmwareRemote: PropTypes.string
};

export default Dashboard;
