import React from "react";
import AlignmentAngle from "./AlignmentAngle";
import RemoteAddress from "./RemoteAddress";
import ShaftType from "./ShaftType";
import SerialNum from "./SerialNum";
import ModelType from "./ModelType";
import DatumToChannel from "./DatumToChannel";
import DatumToMotion from "./DatumToMotion";
//import "./styles.css";

//import _ from "underscore";

import { Grid, GridCell } from '@rmwc/grid';

import {
  List,
  ListItem,
  ListItemText,
  ListItemPrimaryText,
  ListItemSecondaryText,
  ListGroup,
  ListDivider
} from '@rmwc/list';

import {
  Dialog,
  DialogActions,
  DialogButton
} from '@rmwc/dialog';

import '@material/layout-grid/dist/mdc.layout-grid.css';
import '@material/list/dist/mdc.list.css';
import '@material/dialog/dist/mdc.dialog.css';

//const MODEL_NUM_MAJOR_UNKNOWN = 0;
const MODEL_NUM_MAJOR_GEN_2_DRAGON_BOAT = 1;
const MODEL_NUM_MAJOR_GEN_2_HIGH_KNEEL = 2;
const MODEL_NUM_MAJOR_GEN_2_KAYAK_LEFT = 3;
const MODEL_NUM_MAJOR_GEN_2_KAYAK_RIGHT = 4;
const MODEL_NUM_MAJOR_GEN_2_OUTRIGGER = 5;
const MODEL_NUM_MAJOR_GEN_3_DRAGON_BOAT = 6;
const MODEL_NUM_MAJOR_GEN_3_HIGH_KNEEL = 7;
const MODEL_NUM_MAJOR_GEN_3_KAYAK = 8;
const MODEL_NUM_MAJOR_GEN_3_OUTRIGGER = 9;
const MODEL_NUM_MAJOR_GEN_3_SLALOM_CANOE = 10;
const MODEL_NUM_MAJOR_GEN_3_SUP = 11;
const MODEL_NUM_MAJOR_GEN_3_CANOE = 12;

class AdvancedConfiguration extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dialogOpen: false,
    };
  }

  componentDidMount() {
    this.props.readManufacturerParams();
    this.props.readRemoteAddress();
    this.props.readModelNum();
    this.props.readSerialNum();
  }

  handleRemoteAddressChange(remoteaddress) {
    this.setState({
        remoteaddress
    });
  }

  handleSerialNumChange(serialnum) {
    this.setState({
      serialnum
    });
  }

  handleDatumToChannelChange(params) {
    this.setState({
      manufacturerparameters: {
        ...this.props.manufacturerparameters,
        datumToChannelOne: params.datumToChannelOne,
        datumToChannelTwo: params.datumToChannelTwo,
        datumToChannelThree: params.datumToChannelThree,
      }
    });
  }

  handleDatumToMotionChange(params) {
    this.setState({
      manufacturerparameters: {
        ...this.props.manufacturerparameters,
        datumToMotionSensor: params.datumToMotionSensor
      }
    });
  }

  handleAlignmentAngleChange(params) {
    this.setState({
      manufacturerparameters: {
        ...this.props.manufacturerparameters,
        alignmentAngleA: params.alignmentAngleA,
        alignmentAngleB: params.alignmentAngleB
      }
    });
  }

  handleShaftTypeChange(params) {
    this.setState({
      modelnum: {
        ...this.props.modelnum,
        major: params.major
      }
    });
  }

  handleModelTypeChange(params) {
    this.setState({
      modelnum: {
        ...this.props.modelnum,
        minor: params.minor
      }
    });
  }

  // -----

  handleDatumToChannelSubmit() {
    if ((this.state.manufacturerparameters !== undefined) && (this.state.manufacturerparameters.datumToChannelOne !== undefined)) {
      this.setState({
        manufacturerparameters: {
          ...this.props.manufacturerparameters,
          datumToChannelOne: this.state.manufacturerparameters.datumToChannelOne,
          datumToChannelTwo: this.state.manufacturerparameters.datumToChannelTwo,
          datumToChannelThree: this.state.manufacturerparameters.datumToChannelThree
        },
      }, () => {
        this.props.writeManufacturerParams(this.state.manufacturerparameters);
      });
    }
  }

  handleDatumToMotionSubmit() {
    if ((this.state.manufacturerparameters !== undefined) && (this.state.manufacturerparameters.datumToMotionSensor !== undefined)) {
      this.setState({
        manufacturerparameters: {
          ...this.props.manufacturerparameters,
          datumToMotionSensor: this.state.manufacturerparameters.datumToMotionSensor
        },
      }, () => {
        this.props.writeManufacturerParams(this.state.manufacturerparameters);
      });
    }
  }

  handleAlignmentAngleSubmit() {
    if ((this.state.manufacturerparameters !== undefined) && (this.state.manufacturerparameters.alignmentAngleA !== undefined) && (this.state.manufacturerparameters.alignmentAngleB !== undefined)) {
      this.setState({
        manufacturerparameters: {
          ...this.props.manufacturerparameters,
          alignmentAngleA: this.state.manufacturerparameters.alignmentAngleA,
          alignmentAngleB: this.state.manufacturerparameters.alignmentAngleB
        },
      }, () => {
        this.props.writeManufacturerParams(this.state.manufacturerparameters);
      });
    }
  }

  handleShaftTypeSubmit() {
    if ((this.state.modelnum !== undefined) && (this.state.modelnum.major !== undefined)) {
      this.setState({
        modelnum: {
          ...this.props.modelnum,
          major: this.state.modelnum.major
        },
      }, () => {
        this.props.writeModelNum(this.state.modelnum);
      });
    }
  }

  handleModelTypeSubmit() {
    if ((this.state.modelnum !== undefined) && (this.state.modelnum.minor !== undefined)) {
      this.setState({
        modelnum: {
          ...this.props.modelnum,
          minor: this.state.modelnum.minor
        },
      }, () => {
        this.props.writeModelNum(this.state.modelnum);
      });
    }
  }

  handleRemoteAddressSubmit() {
    if (this.state.remoteaddress !== undefined) { 
      this.props.writeRemoteAddress(this.state.remoteaddress);
    }
  }

  handleSerialNumSubmit() {
    if (this.state.serialnum !== undefined) { 
      this.props.writeSerialNum(this.state.serialnum);
    }
  }

  openPopup(content) {
    let c;
    let oc;
    switch (content) {

    case "shafttype":
      c = <ShaftType modelnum={this.props.modelnum} onChange={this.handleShaftTypeChange.bind(this)}/>;
      oc = this.handleShaftTypeSubmit.bind(this);
      break;

    case "shaftserial":
      c = <SerialNum serialnum={this.props.serialnum} onChange={this.handleSerialNumChange.bind(this)}/>;
      oc = this.handleSerialNumSubmit.bind(this);
      break;

    case "datumtochannel":
      let title;
      let labels;
      switch (this.props.modelnum.major)
      {
        case MODEL_NUM_MAJOR_GEN_2_KAYAK_RIGHT:
        case MODEL_NUM_MAJOR_GEN_2_HIGH_KNEEL:
          title = "Datum to channel distances (mm)";
          labels = ["Datum to channel A", "Datum to channel B", "Datum to channel C"];
          break;

        case MODEL_NUM_MAJOR_GEN_2_OUTRIGGER:
        case MODEL_NUM_MAJOR_GEN_2_DRAGON_BOAT:
          title = "Datum to channel distance (mm)";
          labels = ["", "Datum to channel", ""];
          break;

        case MODEL_NUM_MAJOR_GEN_3_DRAGON_BOAT:
        case MODEL_NUM_MAJOR_GEN_3_HIGH_KNEEL:
        case MODEL_NUM_MAJOR_GEN_3_KAYAK:
        case MODEL_NUM_MAJOR_GEN_3_OUTRIGGER:
        case MODEL_NUM_MAJOR_GEN_3_SLALOM_CANOE:
        case MODEL_NUM_MAJOR_GEN_3_SUP:
        case MODEL_NUM_MAJOR_GEN_3_CANOE:
          title = "LED to channel distance (mm)";
          labels = ["LED to channel N", "LED to channel O", ""];
          break;

        default:
          title = "LED to channel distance (mm)";
          labels = ["LED to channel", "", ""];
          break;

      }
      c = <DatumToChannel
            title={title}
            labels={labels}
            manufacturerparameters={this.props.manufacturerparameters}
            onChange={this.handleDatumToChannelChange.bind(this)}/>;
      oc = this.handleDatumToChannelSubmit.bind(this);
      break;

    case "datumtomotion":
      c = <DatumToMotion manufacturerparameters={this.props.manufacturerparameters} onChange={this.handleDatumToMotionChange.bind(this)}/>;
      oc = this.handleDatumToMotionSubmit.bind(this);
      break;

    case "alignmentangle":
      c = <AlignmentAngle manufacturerparameters={this.props.manufacturerparameters} onChange={this.handleAlignmentAngleChange.bind(this)}/>;
      oc = this.handleAlignmentAngleSubmit.bind(this);
      break;

    case "remoteaddress":
      c = <RemoteAddress remoteaddress={this.props.remoteaddress} onChange={this.handleRemoteAddressChange.bind(this)}/>;
      oc = this.handleRemoteAddressSubmit.bind(this);
      break;

    case "modeltype":
      c = <ModelType modelnum={this.props.modelnum} onChange={this.handleModelTypeChange.bind(this)}/>;
      oc = this.handleModelTypeSubmit.bind(this);
      break;

    default:
      c = null;
      oc = () => {};
      break;
    }

    this.setState((ps) => ({
      ...ps,
      content: c,
      dialogOpen: true,
      onConfirm: oc,
    }));
  }

  closePopup() {
    this.setState({
      dialogOpen: false,
    });
  }

  render() {
    return (
      <div>
      {/* Standard Grid. For convenience, GridInner is added for you */}
        <Grid>
          <GridCell span="4"></GridCell>
          <GridCell span="4">
          <ListGroup>
            <List twoLine>
              <ListItem onClick={() => this.openPopup("shafttype")}>
                <ListItemText>
                  <ListItemPrimaryText>Shaft type</ListItemPrimaryText>
                  <ListItemSecondaryText>{shaftType(this.props.modelnum.major)}</ListItemSecondaryText>
                </ListItemText>
              </ListItem>
              <ListDivider/>
              <ListItem onClick={() => this.openPopup("shaftserial")}>
                <ListItemText>
                  <ListItemPrimaryText>Shaft serial number</ListItemPrimaryText>
                  <ListItemSecondaryText>{this.props.serialnum.number}</ListItemSecondaryText>
                </ListItemText>
              </ListItem>
              <ListDivider/>
              {(this.props.modelnum.major !== MODEL_NUM_MAJOR_GEN_2_KAYAK_LEFT) ?
              <>
              <ListItem /*onClick={() => this.openPopup("modeltype")}*/>
                <ListItemText>
                  <ListItemPrimaryText>Model type</ListItemPrimaryText>
                  <ListItemSecondaryText>{modelType(this.props.modelnum.minor)}</ListItemSecondaryText>
                </ListItemText>
              </ListItem>
              <ListDivider/>
              </>  : <> </>}
              {(this.props.modelnum.major === MODEL_NUM_MAJOR_GEN_2_KAYAK_RIGHT) ?
              <>
              <ListItem onClick={() => this.openPopup("remoteaddress")}>
                <ListItemText>
                  <ListItemPrimaryText>Remote address</ListItemPrimaryText>
                  <ListItemSecondaryText>{this.props.remoteaddress.address}</ListItemSecondaryText>
                </ListItemText>
              </ListItem>
              <ListDivider/> 
              </> : <></>}
              {(this.props.modelnum.major !== MODEL_NUM_MAJOR_GEN_2_KAYAK_LEFT) ?
              <>
              <ListItem onClick={() => this.openPopup("datumtochannel")}>
                <ListItemText>
                  {((this.props.modelnum.major === MODEL_NUM_MAJOR_GEN_2_DRAGON_BOAT) ||
                    (this.props.modelnum.major === MODEL_NUM_MAJOR_GEN_2_OUTRIGGER)) ?
                  <>
                    <ListItemPrimaryText>Datum to channel distance</ListItemPrimaryText>
                    <ListItemSecondaryText>{this.props.manufacturerparameters.datumToChannelTwo} mm</ListItemSecondaryText>
                  </>
                  : ((this.props.modelnum.major === MODEL_NUM_MAJOR_GEN_3_DRAGON_BOAT) ||
                    (this.props.modelnum.major === MODEL_NUM_MAJOR_GEN_3_HIGH_KNEEL) ||
                    (this.props.modelnum.major === MODEL_NUM_MAJOR_GEN_3_KAYAK) ||
                    (this.props.modelnum.major === MODEL_NUM_MAJOR_GEN_3_OUTRIGGER) ||
                    (this.props.modelnum.major === MODEL_NUM_MAJOR_GEN_3_SLALOM_CANOE) ||
                    (this.props.modelnum.major === MODEL_NUM_MAJOR_GEN_3_SUP) ||
                    (this.props.modelnum.major === MODEL_NUM_MAJOR_GEN_3_CANOE)) ?
                  <>
                  <ListItemPrimaryText>LED to channel distance</ListItemPrimaryText>
                  <ListItemSecondaryText>{this.props.manufacturerparameters.datumToChannelOne} mm / {this.props.manufacturerparameters.datumToChannelTwo} mm</ListItemSecondaryText>
                  </>
                  :
                  <>
                    <ListItemPrimaryText>Datum to channel distances</ListItemPrimaryText>
                    <ListItemSecondaryText>{this.props.manufacturerparameters.datumToChannelOne} mm / {this.props.manufacturerparameters.datumToChannelTwo} mm / {this.props.manufacturerparameters.datumToChannelThree} mm</ListItemSecondaryText>
                  </>}
                </ListItemText>
              </ListItem>
              <ListDivider/>
              <ListItem onClick={() => this.openPopup("alignmentangle")}>
                <ListItemText>
                  {(this.props.modelnum.major === MODEL_NUM_MAJOR_GEN_2_KAYAK_RIGHT) ?
                  <>
                    <ListItemPrimaryText>Alignment angles</ListItemPrimaryText>
                    <ListItemSecondaryText>Left: {this.props.manufacturerparameters.alignmentAngleB}° / Right: {this.props.manufacturerparameters.alignmentAngleA}°</ListItemSecondaryText>
                  </>
                  :
                  <>
                    <ListItemPrimaryText>Alignment angle</ListItemPrimaryText>
                    <ListItemSecondaryText>{this.props.manufacturerparameters.alignmentAngleA}°</ListItemSecondaryText>
                  </>}
                </ListItemText>
              </ListItem>
             </> : <> </>}
            </List>
            </ListGroup>
          </GridCell>
          <GridCell span="4"></GridCell>
        </Grid>
        <Dialog
            open={this.state.dialogOpen}
            onClose={evt => {
              this.setState({dialogOpen: false})
              if (evt.detail.action === "confirm") {
                this.state.onConfirm()
              }
            }}
          >    
          {this.state.content}
          <DialogActions>
            <DialogButton action="close">Cancel</DialogButton>
            <DialogButton action="confirm" isDefaultAction>Confirm</DialogButton>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const shaftType = (value) => {
  switch (value)
  {
    case 0:
      return 'Unknown';
    case 1:
      return 'Gen 2.1 - Dragon Boat';
    case 2:
      return 'Gen 2.1 - High-Kneel Canoe';
    case 3:
      return 'Gen 2.1 - Kayak (Left Shaft)';
    case 4:
      return 'Gen 2.1 - Kayak (Right Shaft)';
    case 5:
      return 'Gen 2.1 - Outrigger Canoe';
    case 6:
      return 'Gen 3.x - Dragon Boat';
    case 7:
      return 'Gen 3.x - High-Kneel Canoe';
    case 8:
      return 'Gen 3.x - Kayak';
    case 9:
      return 'Gen 3.0/3.1 - Canoe / Dragon Boat / SUP';//originally just Outrigger Canoe
    case 10:
      return 'Gen 3.x - Slalom Canoe';
    case 11:
      return 'Gen 3.x - SUP';
    case 12:
      return 'Gen 3.2 - Canoe / Dragon Boat / SUP';//new
    default:
      break;
  } 
};

const modelType = (value) => {
    switch (value)
    {
        case 0:
            return 'Unknown';
        case 1:
            return 'Standard';
        case 2:
            return 'High Speed Data';
        default:
            break;
    } 
  };

export default AdvancedConfiguration;
