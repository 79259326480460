import React from "react";
import regression from 'regression';

import {
  DataTable,
  DataTableContent,
  DataTableHead,
  DataTableBody,
  DataTableHeadCell,
  DataTableRow,
  DataTableCell
} from '@rmwc/data-table';

import '@rmwc/data-table/data-table.css';

class LinearRegressionGen3 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      plane: 0,
      values: [
        {a: 0, b: 0, orientation: 0},
        {a: 0, b: 0, orientation: 0},
        {a: 0, b: 0, orientation: 0}
      ],
      results: [
        {scalefactor: 0, r2: 0},
        {scalefactor: 0, r2: 0},
        {scalefactor: 0, r2: 0}
      ]
    }
  }

  recalculateRegression() {

    const datumToChannelOne = this.props.datumToChannel[0]/1000.0;
    const datumToChannelTwo = this.props.datumToChannel[1]/1000.0;

    let momentsA = [
      0.0,
      (this.props.weights[1] * 9.81 * (this.props.ledToWeight - datumToChannelOne)),
      (this.props.weights[2] * 9.81 * (this.props.ledToWeight - datumToChannelOne))
    ];

    let momentsB = [
      0.0,
      (this.props.weights[1] * 9.81 * (this.props.ledToWeight - datumToChannelTwo)),
      (this.props.weights[2] * 9.81 * (this.props.ledToWeight - datumToChannelTwo))
    ];

    const resultA = regression.linear([
      [0, momentsA[0]],
      [this.state.values[1].a - this.state.values[0].a, momentsA[1]],
      [this.state.values[2].a - this.state.values[0].a, momentsA[2]]], {
        precision: 3,
      });

    const resultB = regression.linear([
      [0, momentsB[0]],
      [this.state.values[1].b - this.state.values[0].b, momentsB[1]],
      [this.state.values[2].b - this.state.values[0].b, momentsB[2]]], {
        precision: 3,
      });

    this.setState({results: [
      {scalefactor: resultA.equation[0]*1000, r2: resultA.r2},
      {scalefactor: resultB.equation[0]*1000, r2: resultB.r2}
    ]});
  }

  handleRowClick(row) {
    let values = this.state.values;

    let value;

    if (this.state.plane === 0) {
      value = {
        a: this.props.strain[0],
        b: values[row].b,
        orientation: this.props.orientation
      }
    } else {
      value = {
        a: values[row].a,
        b: this.props.strain[1],
        orientation: this.props.orientation
      }
    }

    values[row] = value;

    this.setState({values: values}, () => {
      this.recalculateRegression();
    });

  }

  handleHeaderClick() {
    if (this.state.plane === 0) {
      this.setState({plane: 90});
    } else {
      this.setState({plane: 0});
    }
  }

  handleCreateCalibrationClick() {

    var url = 'https://shop.onegiantleap.co.nz/calibration';

    let scaleFactors = [
      this.state.results[0].scalefactor,
      this.state.results[1].scalefactor,
      1
    ];

    this.props.onCalibrationComplete(this.props.channels, scaleFactors);

    /*fetch(url, {
      method: 'POST', // or 'PUT'
      body: JSON.stringify({
        "scale_factor_a": this.state.results[0].scalefactor,
        "scale_factor_b": this.state.results[1].scalefactor,
        "scale_factor_c": 1,
        "r_squared_a": this.state.results[0].r2,
        "r_squared_b": this.state.results[1].r2,
        "r_squared_c": this.state.results[2].r2,
        "zero_offset_a": this.state.values[0].a,
        "zero_offset_b": this.state.values[0].b,
        "notes": `ledToWeight: ${this.props.ledToWeight}, weights: [${this.props.weights[0]}, ${this.props.weights[1]}, ${this.props.weights[2]}], a: [${this.state.values[0].a}, ${this.state.values[1].a}, ${this.state.values[2].a}], b: [${this.state.values[0].b}, ${this.state.values[1].b}, ${this.state.values[2].b}]`
      }), // data can be `string` or {object}!
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then(response => console.log('Success:', JSON.stringify(response)))
    .catch(error => console.error('Error:', error));*/
  }

  render() {
    return (
      <>
        <DataTable style={{width: "100%"}}>
          <DataTableContent style={{width: "100%"}}>
            <DataTableHead>
              <DataTableRow onClick={() => this.handleHeaderClick()}>
                <DataTableHeadCell>
                  Calibration
                </DataTableHeadCell>
                <DataTableHeadCell alignEnd>{this.props.labels[0]}</DataTableHeadCell>
                <DataTableHeadCell alignEnd>{this.props.labels[1]}</DataTableHeadCell>
              </DataTableRow>
            </DataTableHead>
              <DataTableBody>
                {(this.props.weights[0] != null) ?
                <DataTableRow onClick={() => this.handleRowClick(0)}>
                  <DataTableCell>{this.props.weights[0]} kg</DataTableCell>
                  <DataTableCell alignEnd>
                    {(this.state.plane === 0) ? this.state.values[0].a : "_"}
                  </DataTableCell>
                  <DataTableCell alignEnd>
                    {(this.state.plane === 0) ? "-" : this.state.values[0].b}
                  </DataTableCell>
                </DataTableRow> : <></>}
                {(this.props.weights[1] != null) ?
                <DataTableRow onClick={() => this.handleRowClick(1)}>
                  <DataTableCell>{this.props.weights[1]} kg</DataTableCell>
                  <DataTableCell alignEnd>
                    {(this.state.plane === 0) ? this.state.values[1].a : "-"}
                  </DataTableCell>
                  <DataTableCell alignEnd>
                    {(this.state.plane === 0) ? "-" : this.state.values[1].b}
                  </DataTableCell>
                </DataTableRow> : <></>}
                {(this.props.weights[2] != null) ?
                <DataTableRow onClick={() => this.handleRowClick(2)}>
                  <DataTableCell>{this.props.weights[2]} kg</DataTableCell>
                  <DataTableCell alignEnd>
                    {(this.state.plane === 0) ? this.state.values[2].a : "-"}
                  </DataTableCell>
                  <DataTableCell alignEnd>
                    {(this.state.plane === 0) ? "-" : this.state.values[2].b}
                  </DataTableCell>
                </DataTableRow> : <></>}            
              <DataTableRow onClick={() => this.handleCreateCalibrationClick()}>
                <DataTableCell alignStart>
                  Scale F.
                </DataTableCell>
                <DataTableCell alignEnd>{this.state.results[0].scalefactor}</DataTableCell>
                <DataTableCell alignEnd>{this.state.results[1].scalefactor}</DataTableCell>
              </DataTableRow>
              <DataTableRow>
                <DataTableCell alignStart>
                  R²
                </DataTableCell>
                <DataTableCell alignEnd>{this.state.results[0].r2}</DataTableCell>
                <DataTableCell alignEnd>{this.state.results[1].r2}</DataTableCell>
              </DataTableRow>
            </DataTableBody>
          </DataTableContent>
        </DataTable>
      </>
    )
  }
};

export default LinearRegressionGen3;