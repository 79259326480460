import React from "react";
import regression from 'regression';

import {
  DataTable,
  DataTableContent,
  DataTableHead,
  DataTableBody,
  DataTableHeadCell,
  DataTableRow,
  DataTableCell
} from '@rmwc/data-table';

import '@rmwc/data-table/data-table.css';

class LinearRegressionBladesOn extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      values: [
        {a: 0, b: 0, c: 0, orientation: 0, orientationAlt: 0},
        {a: 0, b: 0, c: 0, orientationAlpha: 0, orientationAlt: 0},
        {a: 0, b: 0, c: 0, orientationAlpha: 0, orientationBeta: 0}
      ],
      results: [
        {scalefactor: 0, r2: 0},
        {scalefactor: 0, r2: 0},
        {scalefactor: 0, r2: 0}
      ]
    }
  }

  recalculateBladesOnRegression() {

    const datumToChannelOne = this.props.datumToChannel[0]/1000.0;
    const datumToChannelTwo = this.props.datumToChannel[1]/1000.0;
    const datumToChannelThree = this.props.datumToChannel[2]/1000.0;

    const farSupportLoad = [
      (this.props.weights[0]*9.81*(this.props.tipToCalWeight - this.props.tipToBlade))/(this.props.tipToShaft - this.props.tipToBlade),
      (this.props.weights[1]*9.81*(this.props.tipToCalWeight - this.props.tipToBlade))/(this.props.tipToShaft - this.props.tipToBlade),
      (this.props.weights[2]*9.81*(this.props.tipToCalWeight - this.props.tipToBlade))/(this.props.tipToShaft - this.props.tipToBlade),
    ];

    const closeSupportLoad = [
      (this.props.weights[0]*9.81) - farSupportLoad[0],
      (this.props.weights[1]*9.81) - farSupportLoad[1],
      (this.props.weights[2]*9.81) - farSupportLoad[2],
    ];

    let momentsA = [
      Math.cos(this.state.values[0].orientation*(Math.PI/180.0))*closeSupportLoad[0]*(this.props.tipToDatum - datumToChannelOne - this.props.tipToBlade),
      Math.cos(this.state.values[1].orientation*(Math.PI/180.0))*closeSupportLoad[1]*(this.props.tipToDatum - datumToChannelOne - this.props.tipToBlade),
      Math.cos(this.state.values[2].orientation*(Math.PI/180.0))*closeSupportLoad[2]*(this.props.tipToDatum - datumToChannelOne - this.props.tipToBlade),
    ];

    let momentsB = [
      Math.cos(this.state.values[0].orientation*(Math.PI/180.0))*farSupportLoad[0]*(this.props.tipToShaft - this.props.tipToDatum + datumToChannelTwo),
      Math.cos(this.state.values[1].orientation*(Math.PI/180.0))*farSupportLoad[1]*(this.props.tipToShaft - this.props.tipToDatum + datumToChannelTwo),
      Math.cos(this.state.values[2].orientation*(Math.PI/180.0))*farSupportLoad[2]*(this.props.tipToShaft - this.props.tipToDatum + datumToChannelTwo),
    ];

    let momentsC = [
      Math.abs(Math.sin(this.state.values[0].orientationAlt*(Math.PI/180.0)))*farSupportLoad[0]*(this.props.tipToShaft - this.props.tipToDatum - this.props.datumToDatum - datumToChannelThree),
      Math.abs(Math.sin(this.state.values[1].orientationAlt*(Math.PI/180.0)))*farSupportLoad[1]*(this.props.tipToShaft - this.props.tipToDatum - this.props.datumToDatum - datumToChannelThree),
      Math.abs(Math.sin(this.state.values[2].orientationAlt*(Math.PI/180.0)))*farSupportLoad[2]*(this.props.tipToShaft - this.props.tipToDatum - this.props.datumToDatum - datumToChannelThree)
    ];

    const resultA = regression.linear([
      [0, momentsA[0]],
      [this.state.values[1].a - this.state.values[0].a, momentsA[1]],
      [this.state.values[2].a - this.state.values[0].a, momentsA[2]]], {
        precision: 3,
      });

    const resultB = regression.linear([
      [0, momentsB[0]],
      [this.state.values[1].b - this.state.values[0].b, momentsB[1]],
      [this.state.values[2].b - this.state.values[0].b, momentsB[2]]], {
        precision: 3,
      });

    const resultC = regression.linear([
      [0, momentsC[0]],
      [this.state.values[1].c - this.state.values[0].c, momentsC[1]],
      [this.state.values[2].c - this.state.values[0].c, momentsC[2]]], {
        precision: 3,
      });

    if (isNaN(resultA.r2)) { resultA.r2 = 0; }
    if (isNaN(resultB.r2)) { resultB.r2 = 0; }
    if (isNaN(resultC.r2)) { resultC.r2 = 0; }

    this.setState({results: [
      {scalefactor: resultA.equation[0]*1000, r2: resultA.r2},
      {scalefactor: resultB.equation[0]*1000, r2: resultB.r2},
      {scalefactor: resultC.equation[0]*1000, r2: resultC.r2}
    ]});
  }

  handleRowClick(row) {
    let values = this.state.values;

    let value = {
      a: this.props.strain[0],
      b: this.props.strain[1],
      c: this.props.strain[2],
      orientation: this.props.orientation,
      orientationAlt: this.props.orientationAlt
    };

    values[row] = value;

    this.setState({values: values}, () => {
      this.recalculateBladesOnRegression();
    });

  }

  handleCreateCalibrationClick() {

    //var url = 'https://shop.onegiantleap.co.nz/calibration';

    let scaleFactors = [
      this.state.results[0].scalefactor,
      this.state.results[1].scalefactor,
      this.state.results[2].scalefactor
    ];

    this.props.onCalibrationComplete(this.props.channels, scaleFactors);

    /*fetch(url, {
      method: 'POST', // or 'PUT'
      body: JSON.stringify({
        "scale_factor_a": this.state.results[0].scalefactor,
        "scale_factor_b": this.state.results[1].scalefactor,
        "scale_factor_c": this.state.results[2].scalefactor,
        "r_squared_a": this.state.results[0].r2,
        "r_squared_b": this.state.results[1].r2,
        "r_squared_c": this.state.results[2].r2,
        "zero_offset_a": this.state.values[0].a,
        "zero_offset_b": this.state.values[0].b,
        "zero_offset_c": this.state.values[0].c,
        "notes": `datumToDatum: ${this.props.datumToDatum}, 
        tipToBlade: ${this.props.tipToBlade}, 
        tipToCalWeight: ${this.props.tipToCalWeight}, 
        tipToDatum: ${this.props.tipToDatum}, 
        tipToShaft: ${this.props.tipToShaft}, 
        shaft: ${this.props.shaft},
        weights: [${this.props.weights[0]}, ${this.props.weights[1]}, ${this.props.weights[2]}], 
        a: [${this.state.values[0].a}, ${this.state.values[1].a}, ${this.state.values[2].a}], 
        b: [${this.state.values[0].b}, ${this.state.values[1].b}, ${this.state.values[2].b}], 
        c: [${this.state.values[0].c}, ${this.state.values[1].c}, ${this.state.values[2].c}],
        orientation: [${this.state.values[0].orientation}, ${this.state.values[1].orientation}, ${this.state.values[2].orientation}],
        orientationAlt: [${this.state.values[0].orientationAlt}, ${this.state.values[1].orientationAlt}, ${this.state.values[2].orientationAlt}]`
      }), // data can be `string` or {object}!
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then(response => console.log('Success:', JSON.stringify(response)))
    .catch(error => console.error('Error:', error));*/
  }

  render() {
    return (
      <>
        <DataTable style={{width: "100%"}}>
          <DataTableContent style={{width: "100%"}}>
            <DataTableHead>
              <DataTableRow>
                <DataTableHeadCell>
                  Weight
                </DataTableHeadCell>
                <DataTableHeadCell alignEnd>{this.props.labels[0]}</DataTableHeadCell>
                <DataTableHeadCell alignEnd>{this.props.labels[1]}</DataTableHeadCell>
                <DataTableHeadCell alignEnd>{this.props.labels[2]}</DataTableHeadCell>
              </DataTableRow>
            </DataTableHead>
              <DataTableBody>
                {(this.props.weights[0] != null) ?
                <DataTableRow onClick={() => this.handleRowClick(0)}>
                  <DataTableCell alignStart>{this.props.weights[0]}</DataTableCell>
                  <DataTableCell alignEnd>{this.state.values[0].a}</DataTableCell>
                  <DataTableCell alignEnd>{this.state.values[0].b}</DataTableCell>
                  <DataTableCell alignEnd>{this.state.values[0].c}</DataTableCell>
                </DataTableRow> : <></>}
                {(this.props.weights[1] != null) ?
                <DataTableRow onClick={() => this.handleRowClick(1)}>
                  <DataTableCell alignStart>{this.props.weights[1]}</DataTableCell>
                  <DataTableCell alignEnd>{this.state.values[1].a}</DataTableCell>
                  <DataTableCell alignEnd>{this.state.values[1].b}</DataTableCell>
                  <DataTableCell alignEnd>{this.state.values[1].c}</DataTableCell>
                </DataTableRow> : <></>}
                {(this.props.weights[2] != null) ?
                <DataTableRow onClick={() => this.handleRowClick(2)}>
                  <DataTableCell alignStart>{this.props.weights[2]}</DataTableCell>
                  <DataTableCell alignEnd>{this.state.values[2].a}</DataTableCell>
                  <DataTableCell alignEnd>{this.state.values[2].b}</DataTableCell>
                  <DataTableCell alignEnd>{this.state.values[2].c}</DataTableCell>
                </DataTableRow> : <></>}
              </DataTableBody> 
            </DataTableContent>
        </DataTable>
        <DataTable style={{width: "100%"}}>
          <DataTableContent style={{width: "100%"}}>
            <DataTableHead>
              <DataTableRow>
                <DataTableHeadCell alignStart>
                  Regression
                </DataTableHeadCell>
                <DataTableHeadCell alignEnd>{this.props.labels[0]}</DataTableHeadCell>
                <DataTableHeadCell alignEnd>{this.props.labels[1]}</DataTableHeadCell>
                <DataTableHeadCell alignEnd>{this.props.labels[2]}</DataTableHeadCell>
              </DataTableRow>
            </DataTableHead>
            <DataTableBody>
              <DataTableRow onClick={() => this.handleCreateCalibrationClick()}>
                <DataTableCell alignStart>
                  Scale F.
                </DataTableCell>
                <DataTableCell alignEnd>{this.state.results[0].scalefactor}</DataTableCell>
                <DataTableCell alignEnd>{this.state.results[1].scalefactor}</DataTableCell>
                <DataTableCell alignEnd>{this.state.results[2].scalefactor}</DataTableCell>
              </DataTableRow>
              <DataTableRow>
                <DataTableCell alignStart>
                  R²
                </DataTableCell>
                <DataTableCell alignEnd>{this.state.results[0].r2}</DataTableCell>
                <DataTableCell alignEnd>{this.state.results[1].r2}</DataTableCell>
                <DataTableCell alignEnd>{this.state.results[2].r2}</DataTableCell>
              </DataTableRow>
            </DataTableBody>
          </DataTableContent>
        </DataTable>
      </>
    )
  }
};

export default LinearRegressionBladesOn;