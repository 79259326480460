import React from "react";
import ScaleFactor from "./ScaleFactor";

import {
  DataTable,
  DataTableContent,
  DataTableHead,
  DataTableBody,
  DataTableHeadCell,
  DataTableRow,
  DataTableCell
} from '@rmwc/data-table';

import {
  Dialog,
  DialogActions,
  DialogButton
} from '@rmwc/dialog';

import '@rmwc/data-table/data-table.css';
import '@material/dialog/dist/mdc.dialog.css';

//const MODEL_NUM_MAJOR_UNKNOWN = 0;
const MODEL_NUM_MAJOR_GEN_2_DRAGON_BOAT = 1;
const MODEL_NUM_MAJOR_GEN_2_HIGH_KNEEL = 2;
//const MODEL_NUM_MAJOR_GEN_2_KAYAK_LEFT = 3;
const MODEL_NUM_MAJOR_GEN_2_KAYAK_RIGHT = 4;
const MODEL_NUM_MAJOR_GEN_2_OUTRIGGER = 5;
const MODEL_NUM_MAJOR_GEN_3_DRAGON_BOAT = 6;
const MODEL_NUM_MAJOR_GEN_3_HIGH_KNEEL = 7;
const MODEL_NUM_MAJOR_GEN_3_KAYAK = 8;
const MODEL_NUM_MAJOR_GEN_3_OUTRIGGER = 9;
const MODEL_NUM_MAJOR_GEN_3_SLALOM_CANOE = 10;
const MODEL_NUM_MAJOR_GEN_3_SUP = 11;
const MODEL_NUM_MAJOR_GEN_3_CANOE = 12;

class LiveOutput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dialogOpen: false,
      scaleFactors: props.scaleFactors
    }

    this.handleZeroOffsetClick = this.handleZeroOffsetClick.bind(this);
  }

  componentWillReceiveProps(np) {
    if (this.state.scaleFactors[0] === 0) {
      this.setState({scaleFactors: np.scaleFactors});
    }
  }

  handleScaleFactorChange(scaleFactors) {
    console.log(scaleFactors);
    this.setState({
      scaleFactors: scaleFactors
    });
  }

  handleSubmit() {
    this.props.onSubmit(this.props.channels, this.state.scaleFactors);
  }

  handleZeroOffsetClick() {
    this.props.onZeroOffsetSubmit(this.props.channels);
  }

  handleScaleFactorClick() {
    let c;
    let oc;

    c = <ScaleFactor
          modelnum={this.props.modelnum}
          labels={this.props.labels}
          scaleFactors={this.state.scaleFactors}
          onChange={this.handleScaleFactorChange.bind(this)}/>;
    oc = this.handleSubmit.bind(this);

    this.setState((ps) => ({
      ...ps,
      content: c,
      dialogOpen: true,
      onConfirm: oc,
    }));
  }

  closePopup() {
    this.setState({
      dialogOpen: false,
    });
  }

  render() {
    return (
      <>
      <DataTable style={{width: "100%"}}>
        <DataTableContent style={{width: "100%"}}>
          <DataTableHead>
            <DataTableRow>
              <DataTableHeadCell alignStart>{this.props.orientation}°</DataTableHeadCell>
              {((this.props.modelnum.major === MODEL_NUM_MAJOR_GEN_2_KAYAK_RIGHT) ||
                (this.props.modelnum.major === MODEL_NUM_MAJOR_GEN_2_HIGH_KNEEL)) ?
              <>
              <DataTableHeadCell alignEnd>{this.props.labels[0]}</DataTableHeadCell>
              <DataTableHeadCell alignEnd>{this.props.labels[1]}</DataTableHeadCell>
              <DataTableHeadCell alignEnd>{this.props.labels[2]}</DataTableHeadCell>
              </>
              : ((this.props.modelnum.major === MODEL_NUM_MAJOR_GEN_3_DRAGON_BOAT) ||
                (this.props.modelnum.major === MODEL_NUM_MAJOR_GEN_3_HIGH_KNEEL) ||
                (this.props.modelnum.major === MODEL_NUM_MAJOR_GEN_3_KAYAK) ||
                (this.props.modelnum.major === MODEL_NUM_MAJOR_GEN_3_OUTRIGGER) ||
                (this.props.modelnum.major === MODEL_NUM_MAJOR_GEN_3_SLALOM_CANOE) || 
                (this.props.modelnum.major === MODEL_NUM_MAJOR_GEN_3_SUP) ||
                (this.props.modelnum.major === MODEL_NUM_MAJOR_GEN_3_CANOE)) ?
              <>
                <DataTableHeadCell alignEnd>N</DataTableHeadCell>
                <DataTableHeadCell alignEnd>O</DataTableHeadCell>
              </>
              :
              <DataTableHeadCell alignEnd></DataTableHeadCell>}
            </DataTableRow>
            </DataTableHead>
            {((this.props.modelnum.major === MODEL_NUM_MAJOR_GEN_2_KAYAK_RIGHT) ||
              (this.props.modelnum.major === MODEL_NUM_MAJOR_GEN_2_HIGH_KNEEL)) ?
            <DataTableBody>
              <DataTableRow>
                <DataTableCell alignStart style={{fontSize: "10px"}}>Live Output</DataTableCell>
                <DataTableCell alignEnd>{this.props.strain[0]}</DataTableCell>
                <DataTableCell alignEnd>{this.props.strain[1]}</DataTableCell>
                <DataTableCell alignEnd>{this.props.strain[2]}</DataTableCell>
              </DataTableRow>
              <DataTableRow onClick={() => this.handleZeroOffsetClick()}>
                <DataTableCell alignStart style={{fontSize: "10px"}}>Zero Offset</DataTableCell>
                <DataTableCell alignEnd>{this.props.zeroOffsets[0]}</DataTableCell>
                <DataTableCell alignEnd>{this.props.zeroOffsets[1]}</DataTableCell>
                <DataTableCell alignEnd>{this.props.zeroOffsets[2]}</DataTableCell>
              </DataTableRow>
              <DataTableRow onClick={() => this.handleScaleFactorClick()}>
                <DataTableCell alignStart style={{fontSize: "10px"}}>Scale Factor</DataTableCell>
                <DataTableCell alignEnd>{this.props.scaleFactors[0]}</DataTableCell>
                <DataTableCell alignEnd>{this.props.scaleFactors[1]}</DataTableCell>
                <DataTableCell alignEnd>{this.props.scaleFactors[2]}</DataTableCell>
              </DataTableRow>
            </DataTableBody>
            : ((this.props.modelnum.major === MODEL_NUM_MAJOR_GEN_3_DRAGON_BOAT) ||
            (this.props.modelnum.major === MODEL_NUM_MAJOR_GEN_3_HIGH_KNEEL) ||
            (this.props.modelnum.major === MODEL_NUM_MAJOR_GEN_3_KAYAK) ||
            (this.props.modelnum.major === MODEL_NUM_MAJOR_GEN_3_OUTRIGGER) ||
            (this.props.modelnum.major === MODEL_NUM_MAJOR_GEN_3_SLALOM_CANOE) || 
            (this.props.modelnum.major === MODEL_NUM_MAJOR_GEN_3_SUP) ||
            (this.props.modelnum.major === MODEL_NUM_MAJOR_GEN_3_CANOE)) ?
            <DataTableBody>
              <DataTableRow>
                <DataTableCell alignStart style={{fontSize: "10px"}}>Live Output</DataTableCell>
                <DataTableCell alignEnd>{this.props.strain[0]}</DataTableCell>
                <DataTableCell alignEnd>{this.props.strain[1]}</DataTableCell>
              </DataTableRow>
              <DataTableRow onClick={() => this.handleZeroOffsetClick()}>
                <DataTableCell alignStart style={{fontSize: "10px"}}>Zero Offset</DataTableCell>
                <DataTableCell alignEnd>{this.props.zeroOffsets[0]}</DataTableCell>
                <DataTableCell alignEnd>{this.props.zeroOffsets[1]}</DataTableCell>
              </DataTableRow>
              <DataTableRow onClick={() => this.handleScaleFactorClick()}>
                <DataTableCell alignStart style={{fontSize: "10px"}}>Scale Factor</DataTableCell>
                <DataTableCell alignEnd>{this.props.scaleFactors[0]}</DataTableCell>
                <DataTableCell alignEnd>{this.props.scaleFactors[1]}</DataTableCell>
              </DataTableRow>
            </DataTableBody>
            : ((this.props.modelnum.major === MODEL_NUM_MAJOR_GEN_2_DRAGON_BOAT) ||
               (this.props.modelnum.major === MODEL_NUM_MAJOR_GEN_2_OUTRIGGER)) ?
            <DataTableBody>
              <DataTableRow>
                <DataTableCell alignStart style={{fontSize: "10px"}}>Live Output</DataTableCell>
                <DataTableCell alignEnd>{this.props.strain[1]}</DataTableCell>
              </DataTableRow>
              <DataTableRow onClick={() => this.handleZeroOffsetClick()}>
                <DataTableCell alignStart style={{fontSize: "10px"}}>Zero Offset</DataTableCell>
                <DataTableCell alignEnd>{this.props.zeroOffsets[1]}</DataTableCell>
              </DataTableRow>
              <DataTableRow onClick={() => this.handleScaleFactorClick()}>
                <DataTableCell alignStart style={{fontSize: "10px"}}>Scale Factor</DataTableCell>
                <DataTableCell alignEnd>{this.props.scaleFactors[1]}</DataTableCell>
              </DataTableRow>
            </DataTableBody>
            :
            <DataTableBody>
                <DataTableRow>
                <DataTableCell alignStart style={{fontSize: "10px"}}>Live Output</DataTableCell>
                <DataTableCell alignEnd>{this.props.strain[0]}</DataTableCell>
              </DataTableRow>
              <DataTableRow onClick={() => this.handleZeroOffsetClick()}>
                <DataTableCell alignStart style={{fontSize: "10px"}}>Zero Offset</DataTableCell>
                <DataTableCell alignEnd>{this.props.zeroOffsets[0]}</DataTableCell>
              </DataTableRow>
              <DataTableRow onClick={() => this.handleScaleFactorClick()}>
                <DataTableCell alignStart style={{fontSize: "10px"}}>Scale Factor</DataTableCell>
                <DataTableCell alignEnd>{this.props.scaleFactors[0]}</DataTableCell>
              </DataTableRow>
            </DataTableBody>
          }
          </DataTableContent>
      </DataTable>
      <Dialog
        open={this.state.dialogOpen}
        onClose={evt => {
          if (evt.detail.action === "confirm") {
            this.state.onConfirm()
          }
          this.setState({dialogOpen: false})
        }}
      >    
        {this.state.content}
        <DialogActions>
          <DialogButton action="close">Cancel</DialogButton>
          <DialogButton action="confirm" isDefaultAction>Confirm</DialogButton>
        </DialogActions>
      </Dialog>
      </>
    );
  }
}

export default LiveOutput;