import React from 'react';
import "./styles.css";
import _ from "underscore";

import {
  DialogTitle,
  DialogContent
} from '@rmwc/dialog';

import { Select } from '@rmwc/select';

import '@material/dialog/dist/mdc.dialog.css';
import '@material/floating-label/dist/mdc.floating-label.css';
import '@material/notched-outline/dist/mdc.notched-outline.css';
import '@material/line-ripple/dist/mdc.line-ripple.css';
import '@material/select/dist/mdc.select.css';
import '@material/dialog/dist/mdc.dialog.css';

//const MODEL_NUM_MAJOR_UNKNOWN = 0;
const MODEL_NUM_MAJOR_GEN_2_DRAGON_BOAT = 1;
const MODEL_NUM_MAJOR_GEN_2_HIGH_KNEEL = 2;
//const MODEL_NUM_MAJOR_GEN_2_KAYAK_LEFT = 3;
const MODEL_NUM_MAJOR_GEN_2_KAYAK_RIGHT = 4;
const MODEL_NUM_MAJOR_GEN_2_OUTRIGGER = 5;
const MODEL_NUM_MAJOR_GEN_3_DRAGON_BOAT = 6;
const MODEL_NUM_MAJOR_GEN_3_HIGH_KNEEL = 7;
const MODEL_NUM_MAJOR_GEN_3_KAYAK = 8;
const MODEL_NUM_MAJOR_GEN_3_OUTRIGGER = 9;
const MODEL_NUM_MAJOR_GEN_3_SLALOM_CANOE = 10;
const MODEL_NUM_MAJOR_GEN_3_SUP = 11;
const MODEL_NUM_MAJOR_GEN_3_CANOE = 12;

let optionNodes = [];

class BladeModel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      bladeparameters: props.bladeparameters,
    };

    switch (props.modelnum)
    {
      case MODEL_NUM_MAJOR_GEN_2_DRAGON_BOAT:
      case MODEL_NUM_MAJOR_GEN_2_HIGH_KNEEL:
      case MODEL_NUM_MAJOR_GEN_2_KAYAK_RIGHT:
      case MODEL_NUM_MAJOR_GEN_2_OUTRIGGER:
      {
        optionNodes = this.props.bladelist.map(function(blade){
          return (
            {
              label: `${blade.manufacturer} - ${blade.model} ${blade.size}`,
              value: blade.bladeId,
              key: blade.bladeId
            }
          )
        });

        break;
      }

      case MODEL_NUM_MAJOR_GEN_3_DRAGON_BOAT:
      case MODEL_NUM_MAJOR_GEN_3_HIGH_KNEEL:
      case MODEL_NUM_MAJOR_GEN_3_KAYAK:
      case MODEL_NUM_MAJOR_GEN_3_OUTRIGGER:
      case MODEL_NUM_MAJOR_GEN_3_SLALOM_CANOE:
      case MODEL_NUM_MAJOR_GEN_3_SUP:
      case MODEL_NUM_MAJOR_GEN_3_CANOE:
      {
        optionNodes = this.props.bladelist.filter(function(blade){
          if (blade.tipToCoP === ""){
            return false;
          }
          return true;
        }).map(function(blade){
          return (
            {
              label: `${blade.manufacturer} - ${blade.model} ${blade.size}`,
              value: blade.bladeId,
              key: blade.bladeId
            }
          )
        });

        break;
      }

      default:
        break;
    }


    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const blade = _.findWhere(this.props.bladelist, {bladeId: parseInt(event.target.value)});
    this.setState({
      bladeparameters: {
        ...this.state.bladeparameters,
        bladeLength: blade.bladeLength,
        tipToCoP: blade.tipToCoP,
        kickbackAngle: blade.kickbackAngle,
        equivLength: blade.equivLength,
        equivWidth: blade.equivWidth,
        bladeId: blade.bladeId
      },
    }, () => {
      this.props.onChange(this.state.bladeparameters);
    });
  }

  render() {
    return (
      <div>
        <DialogTitle>Blade type</DialogTitle>
        <DialogContent style={{paddingTop: '15px'}}>
          <Select
          id="model"
          outlined
          className="full_width"
          value={this.state.bladeparameters.bladeId}
          onChange={(event) => this.handleChange(event)}
          label="Blade type"
          placeholder="Unknown"
          options={optionNodes}
        />
      </DialogContent>
    </div>
    );
  };
};

export default BladeModel;