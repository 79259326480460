import React from "react";
import {render} from "react-dom";
import "./index.css";
import App from "./App";
import {createStore, applyMiddleware} from "redux";
import {Provider} from "react-redux";
import thunk from "redux-thunk";
import registerServiceWorker from "./registerServiceWorker";
import AppReducer from "./reducers/reducers";

import { ThemeProvider } from '@rmwc/theme';

import '@material/theme/dist/mdc.theme.css';
import '@rmwc/icon/icon.css';

require("typeface-roboto");

const store = createStore(AppReducer, applyMiddleware(thunk));

render(
  <Provider store={store}>
    <ThemeProvider options={{
      primary: '#ea8635',
      secondary: '#eae135',
      error: '#b00020',
      background: '#fff',
      surface: '#fff',
      onPrimary: 'rgba(0, 0, 0, 0.87)',
      onSecondary: 'rgba(0, 0, 0, 0.87)',
      onSurface: 'rgba(0, 0, 0, 0.87)',
      onError: '',
      textPrimaryOnBackground: 'rgba(0, 0, 0, 0.87)',
      textSecondaryOnBackground: 'rgba(0, 0, 0, 0.54)',
      textHintOnBackground: 'rgba(0, 0, 0, 0.38)',
      textDisabledOnBackground: 'rgba(0, 0, 0, 0.38)',
      textIconOnBackground: 'rgba(0, 0, 0, 0.38)',
      textPrimaryOnLight: 'rgba(0, 0, 0, 0.87)',
      textSecondaryOnLight: 'rgba(0, 0, 0, 0.54)',
      textHintOnLight: 'rgba(0, 0, 0, 0.38)',
      textDisabledOnLight: 'rgba(0, 0, 0, 0.38)',
      textIconOnLight: 'rgba(0, 0, 0, 0.38)',
      textPrimaryOnDark: 'white',
      textSecondaryOnDark: 'rgba(255, 255, 255, 0.7)',
      textHintOnDark: 'rgba(255, 255, 255, 0.5)',
      textDisabledOnDark: 'rgba(255, 255, 255, 0.5)',
      textIconOnDark: 'rgba(255, 255, 255, 0.5)'
    }}>
      <App />
    </ThemeProvider>
  </Provider>,
  document.getElementById("root"),
);

registerServiceWorker();
