import React from "react";

import {
  DialogTitle,
  DialogContent
} from '@rmwc/dialog';

import { TextField } from '@rmwc/textfield';

import '@material/dialog/dist/mdc.dialog.css';
import '@material/textfield/dist/mdc.textfield.css';
import '@material/floating-label/dist/mdc.floating-label.css';
import '@material/notched-outline/dist/mdc.notched-outline.css';
import '@material/line-ripple/dist/mdc.line-ripple.css';

class AlignmentAngle extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        manufacturerparameters: props.manufacturerparameters,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    if (event.target.id === "alignmentAngleA") {
      this.setState({
        manufacturerparameters: {
          ...this.state.manufacturerparameters,
          alignmentAngleA: event.target.valueAsNumber,
        },
      }, () => {
        this.props.onChange(this.state.manufacturerparameters);
      });
    } else if (event.target.id === "alignmentAngleB") {
      this.setState({
        manufacturerparameters: {
          ...this.state.manufacturerparameters,
          alignmentAngleB: event.target.valueAsNumber,
        },
      }, () => {
        this.props.onChange(this.state.manufacturerparameters);
      });
    }
  }
  render() {
    return (
      <div>
        <DialogTitle>Alignment angles (°)</DialogTitle>
        <DialogContent style={{paddingTop: '15px'}}>
          <TextField
            id="alignmentAngleB"
            outlined
            type="number"
            step="0.1"
            style={{width: '100%'}}
            label="Left shaft"
            value={this.state.manufacturerparameters.alignmentAngleB}
            onChange={(event) => this.handleChange(event)}/>
          <br/>
          <br/>
          <TextField
            id="alignmentAngleA"
            outlined
            type="number"
            step="0.1"
            style={{width: '100%'}}
            label="Right shaft"
            value={this.state.manufacturerparameters.alignmentAngleA}
            onChange={(event) => this.handleChange(event)}/>
        </DialogContent>
      </div>
    );
  };
};

export default AlignmentAngle;