import React from "react";
import { TextField } from '@rmwc/textfield';
import { Select } from '@rmwc/select';
import {
  DialogTitle,
  DialogContent
} from '@rmwc/dialog';

import '@material/select/dist/mdc.select.css';
import '@material/floating-label/dist/mdc.floating-label.css';
import '@material/notched-outline/dist/mdc.notched-outline.css';
import '@material/line-ripple/dist/mdc.line-ripple.css';
import '@material/list/dist/mdc.list.css';
import '@material/menu/dist/mdc.menu.css';
import '@material/menu-surface/dist/mdc.menu-surface.css';

import '@material/dialog/dist/mdc.dialog.css';
import '@material/textfield/dist/mdc.textfield.css';
import "./styles.css";

class BladeTwist extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      bladetwist: props.bladetwist,
    };

    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(event) {
    if (event.target.id === "hand") {
      this.setState({
        bladetwist: {
          ...this.state.bladetwist,
          hand: event.target.value,
        },
      }, () => {
        this.props.onChange(this.state.bladetwist);
      });
    } else if (event.target.id === "angle") {
      this.setState({
        bladetwist: {
          ...this.state.bladetwist,
          angle: event.target.valueAsNumber,
        },
      }, () => {
        this.props.onChange(this.state.bladetwist);
      });
    }
  }
  render() {
    return (
      <div>
        <DialogTitle>Blade twist</DialogTitle>
        <DialogContent style={{paddingTop: '15px'}}>
          <Select
            id="hand"
            outlined
            className="full_width"
            value={this.state.bladetwist.hand}
            onChange={(event) => this.handleChange(event)}
            label="Blade twist hand"
            placeholder="Unknown"
            options={[
              {
                label: 'Left hand twist',
                value: '1'
              },
              {
                label: 'Right hand twist',
                value: '2',
              }
            ]}
          />
          <br/>
          <br/>
          <TextField
            id="angle"
            type="number"
            outlined
            style={{width: '100%'}}
            label="Blade twist angle (degrees)"
            value={this.state.bladetwist.angle}
            onChange={(event) => this.handleChange(event)}/>
        </DialogContent>
      </div>
    );
  };
};

export default BladeTwist;

/*import React from "react";
import "./styles.css";

class BladeTwist extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      bladetwist: props.bladetwist,
    };

    this.handleBladeTwistChange = this.handleBladeTwistChange.bind(this);
  }

  handleBladeTwistChange(event) {
    if (event.target.id === "hand") {
      this.setState({
        bladetwist: {
          ...this.state.bladetwist,
          hand: event.target.value,
        },
      }, () => {
        this.props.onChange(this.state.bladetwist);
      });
    } else if (event.target.id === "angle") {
      this.setState({
        bladetwist: {
          ...this.state.bladetwist,
          angle: event.target.value,
        },
      }, () => {
        this.props.onChange(this.state.bladetwist);
      });
    }
  }

  render() {
    return (
      <div>
        <div className="modal">
          <div className="content">
            <div className="header">Blade twist settings</div>
            <p className="info">Blade twist hand</p>
            <select id="hand" value={this.state.bladetwist.hand} onChange={(event) => this.handleBladeTwistChange(event)}>
              <option value="0" disabled>Unknown</option>
              <option value="1">Left hand twist</option>
              <option value="2">Right hand twist</option>
            </select>
            <div className="underline"></div>
            <p className="info">Blade twist angle (degrees)</p>
            <input id="angle" type="number" value={this.state.bladetwist.angle} onChange={(event) => this.handleBladeTwistChange(event)} min="0" max="120" ></input>
            <div className="underline"></div>
          </div>
        </div>
      </div>
    );
  };
};

export default BladeTwist;*/