import React from "react";

import {
  DialogTitle,
  DialogContent
} from '@rmwc/dialog';

import '@material/dialog/dist/mdc.dialog.css';

export const DeepSleep = () => (
  <div>
    <DialogTitle>Enter deep sleep mode</DialogTitle>
    <DialogContent>
      Once in a deep sleep, the power meter wil remain asleep and will only wake up when a wireless charger has been attached.<br/>
      Do you wish to continue?
    </DialogContent>
  </div>
);

export default DeepSleep;