import React from "react";

import {
  DialogTitle,
  DialogContent
} from '@rmwc/dialog';

import { Select } from '@rmwc/select';

import '@material/dialog/dist/mdc.dialog.css';
import '@material/floating-label/dist/mdc.floating-label.css';
import '@material/notched-outline/dist/mdc.notched-outline.css';
import '@material/line-ripple/dist/mdc.line-ripple.css';
import '@material/select/dist/mdc.select.css';
//import "./styles.css";

class ModelType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modelnum: props.modelnum,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({
      modelnum: {
          ...this.state.modelnum,
          minor: event.target.value,
      },
    }, () => {
      this.props.onChange(this.state.modelnum);
    });
  }

  render() {
    return (
      <div>
        <DialogTitle>Shaft type</DialogTitle>
        <DialogContent style={{paddingTop: '15px'}}>
          <Select
            id="minor"
            outlined
            className="full_width"
            value={this.state.modelnum.minor}
            onChange={(event) => this.handleChange(event)}
            label="Model type"
            placeholder="Unknown"
            options={[
              {
                label: 'Standard',
                value: '1'
              },
              {
                label: 'High Speed Data',
                value: '2',
              }
            ]}
          />
        </DialogContent>
      </div>
    );
  }
}

export default ModelType;
