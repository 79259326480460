import {connect} from "react-redux";
import {toggleFeature, changeCardTab} from "../actions/misc";
import Metrics from "../components/Metrics/Metrics";

const mapStateToProps = ({misc}) => {
  return ({
    forcepeak: misc.forcepeak,
    forceaverage: misc.forceaverage,
    forceeffectiveaverage: misc.forceeffectiveaverage,
    forceeffectivepercent: misc.forceeffectivepercent,
    impulse: misc.impulse,
    impulseeffective: misc.impulseeffective
  });
};

const mapDispatchToProps = (dispatch) => ({
  toggleForcePeak: () => {
    dispatch(toggleFeature("forcepeak"));
  },
  toggleForceAverage: () => {
    dispatch(toggleFeature("forceaverage"));
  },
  toggleForceEffectiveAverage: () => {
    dispatch(toggleFeature("forceeffectiveaverage"));
  },
  toggleForceEffectivePercent: () => {
    dispatch(toggleFeature("forceeffectivepercent"));
  },
  toggleImpulse: () => {
    dispatch(toggleFeature("impulse"));
  },
  toggleImpulseEffective: () => {
    dispatch(toggleFeature("impulseeffective"));
  },
  changeTab: (feature, tab) => {
    dispatch(changeCardTab(feature, tab));
  },
  toggleAll: (toggle) => {
    //dispatch(toggleMetrics(toggle));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Metrics);
