import React from "react";

//import PropTypes from "prop-types";
import LiveOutput from "./LiveOutput";
import LinearRegression from "./LinearRegression";
import LinearRegressionGen3 from "./LinearRegressionGen3";
import LinearRegressionBladesOn from "./LinearRegressionBladesOn";
import LMS from "./LMS";
//import check from "../../assets/check.svg";

import { Grid, GridCell } from '@rmwc/grid';

import "./styles.css";
import '@material/layout-grid/dist/mdc.layout-grid.css';

//const MODEL_NUM_MAJOR_UNKNOWN = 0;
//const MODEL_NUM_MAJOR_GEN_2_DRAGON_BOAT = 1;
//const MODEL_NUM_MAJOR_GEN_2_HIGH_KNEEL = 2;
//const MODEL_NUM_MAJOR_GEN_2_KAYAK_LEFT = 3;
const MODEL_NUM_MAJOR_GEN_2_KAYAK_RIGHT = 4;
//const MODEL_NUM_MAJOR_GEN_2_OUTRIGGER = 5;
//const MODEL_NUM_MAJOR_GEN_3_DRAGON_BOAT = 6;
//const MODEL_NUM_MAJOR_GEN_3_HIGH_KNEEL = 7;
//const MODEL_NUM_MAJOR_GEN_3_KAYAK = 8;
//const MODEL_NUM_MAJOR_GEN_3_OUTRIGGER = 9;
//const MODEL_NUM_MAJOR_GEN_3_SLALOM_CANOE = 10;
//const MODEL_NUM_MAJOR_GEN_3_SUP = 11;

class Calibration extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      zerooffsets: props.zerooffsets,
      scalefactors: props.scalefactors
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleZeroOffsetSubmit = this.handleZeroOffsetSubmit.bind(this);
  }

  componentDidMount() {
    this.props.enableStrain();
    this.props.enableOrientation();
    this.props.readZeroOffsets();
    this.props.readScaleFactors();
    this.props.readManufacturerParams();

    let params = new URLSearchParams(this.props.location.search);
    let paramsSize = params.size;

    console.log(paramsSize);
    let tipToClose = parseFloat(params.get("tipToClose"));
    let tipToCOP = parseFloat(params.get("tipToCOP"));
    let tipToDatum = parseFloat(params.get("tipToDatum"));
    let tipToFar = parseFloat(params.get("tipToFar"));
    let weights = [parseFloat("0.00"), parseFloat(params.get("weight1")), parseFloat(params.get("weight2"))];
    let ledToWeight = parseFloat(params.get('ledToWeight'));//added for Gen 3.1 factory calibration
    
    let datumToDatum = parseFloat(params.get("datumToDatum"));
    let tipToCalWeight = parseFloat(params.get("tipToCalWeight"));
    let tipToBlade = parseFloat(params.get("tipToBlade"));
    let tipToShaft = parseFloat(params.get("tipToShaft"));
    let shaft = params.get("shaft");

    let lms = params.get("LMS");

    // Convert the parameter value to a boolean
    const lmsBoolean = lms === 'true';

    this.setState({
      paramsSize: paramsSize,
      tipToClose: tipToClose,
      tipToCOP: tipToCOP,
      tipToDatum: tipToDatum,
      tipToFar: tipToFar,
      datumToDatum: datumToDatum,
      tipToBlade: tipToBlade,
      tipToShaft: tipToShaft,
      tipToCalWeight: tipToCalWeight,
      weights: weights,
      shaft: shaft,
      ledToWeight: ledToWeight,
      lmsBoolean: lmsBoolean
    });
  }

  componentWillUnmount() {
    this.props.disableStrain();
    this.props.disableOrientation();
  }

  handleSubmit(channels, scaleFactors) {

    let temp = this.state.scalefactors;

    temp[channels[0]] = scaleFactors[0];
    temp[channels[1]] = scaleFactors[1];
    temp[channels[2]] = scaleFactors[2];

    this.props.writeScaleFactors(temp);
  }

  handleZeroOffsetSubmit(channels) { 
    let temp = this.state.zerooffsets;

    temp[channels[0]] = this.props.strain.reading[channels[0]];
    temp[channels[1]] = this.props.strain.reading[channels[1]];
    temp[channels[2]] = this.props.strain.reading[channels[2]];
    this.setState({
      zerooffsets: temp,
    }, () => {
      this.props.writeZeroOffsets(this.state.zerooffsets);
    });
  }

  render() {
    let labels = ["A", "B", "C"];
    let channels = [0, 1, 2];
    let orientation;
    let orientationAlt;

    if (this.state.shaft === "right") {
      if (!(isNaN(this.state.tipToCalWeight))) {
        labels = ["Right A", "Right B", "Left C"];
        orientation = (this.props.orientation.reading.a/10)
        orientationAlt = (this.props.orientation.reading.b/10)
        channels = [0, 1, 5];
      } else {
        labels = ["Right A", "Right B", "Right C"];
        channels = [0, 1, 2];
      }
    } else if (this.state.shaft === "left") {
      if (!(isNaN(this.state.tipToCalWeight))) {
        labels = ["Left A", "Left B", "Right C"];
        orientation = (this.props.orientation.reading.b/10);
        orientationAlt = (this.props.orientation.reading.a/10);
        channels = [3, 4, 2];
      } else {
        labels = ["Left A", "Left B", "Left C"];
        channels = [3, 4, 5];
      }
    } else if (!(isNaN(this.state.ledToWeight))) {
        labels = ["N", "0"];
        orientation = (this.props.orientation.reading.a/10);
        channels= [0, 1, 2];
    }

    return (
      <>
        {(this.state.paramsSize == 0) ?
        <Grid>
          {(this.props.modelnum.major === MODEL_NUM_MAJOR_GEN_2_KAYAK_RIGHT) ?
          <>
          <GridCell span="6">
            <LiveOutput
              labels={["Left A", "Left B", "Left C"]}
              modelnum={this.props.modelnum}
              strain={[this.props.strain.reading[3], this.props.strain.reading[4], this.props.strain.reading[5]]}
              orientation={(this.props.orientation.reading.b/10).toFixed(1)}
              channels={[3, 4, 5]}
              zeroOffsets={[this.props.zerooffsets[3], this.props.zerooffsets[4], this.props.zerooffsets[5]]}
              scaleFactors={[this.props.scalefactors[3], this.props.scalefactors[4], this.props.scalefactors[5]]}
              onZeroOffsetSubmit={this.handleZeroOffsetSubmit}
              onSubmit={this.handleSubmit}/>
          </GridCell>
          <GridCell span="6">
            <LiveOutput
              labels={["Right A", "Right B", "Right C"]}
              modelnum={this.props.modelnum}
              strain={[this.props.strain.reading[0], this.props.strain.reading[1], this.props.strain.reading[2]]}
              orientation={(this.props.orientation.reading.a/10).toFixed(1)}
              channels={[0, 1, 2]}
              zeroOffsets={[this.props.zerooffsets[0], this.props.zerooffsets[1], this.props.zerooffsets[2]]}
              scaleFactors={[this.props.scalefactors[0], this.props.scalefactors[1], this.props.scalefactors[2]]}
              onZeroOffsetSubmit={this.handleZeroOffsetSubmit}
              onSubmit={this.handleSubmit}/>
          </GridCell>
          </>
          :
          <>
          <GridCell span="3"></GridCell>
          <GridCell span="6">
          <LiveOutput
            labels={["N", "O", ""]}
            modelnum={this.props.modelnum}
            strain={[this.props.strain.reading[0], this.props.strain.reading[1], this.props.strain.reading[2]]}
            orientation={(this.props.orientation.reading.a/10).toFixed(1)}
            channels={[0, 1, 2]}
            zeroOffsets={[this.props.zerooffsets[0], this.props.zerooffsets[1], this.props.zerooffsets[2]]}
            scaleFactors={[this.props.scalefactors[0], this.props.scalefactors[1], this.props.scalefactors[2]]}
            onZeroOffsetSubmit={this.handleZeroOffsetSubmit}
            onSubmit={this.handleSubmit}/>
        </GridCell>
        <GridCell span="3"></GridCell>
        </>}
        </Grid> :
        <Grid>
          <GridCell span="3"></GridCell>
          <GridCell span="6">
            <LiveOutput
              labels={labels}
              modelnum={this.props.modelnum}
              strain={[this.props.strain.reading[channels[0]], this.props.strain.reading[channels[1]], this.props.strain.reading[channels[2]]]}
              orientation={`${(this.props.orientation.reading.b/10).toFixed(1)} / ${(this.props.orientation.reading.a/10).toFixed(1)}`}
              channels={channels}
              zeroOffsets={[this.props.zerooffsets[channels[0]], this.props.zerooffsets[channels[1]], this.props.zerooffsets[channels[2]]]}
              scaleFactors={[this.props.scalefactors[channels[0]], this.props.scalefactors[channels[1]], this.props.scalefactors[channels[2]]]}
              onZeroOffsetSubmit={this.handleZeroOffsetSubmit}
              onSubmit={this.handleSubmit}/>
            {(!(isNaN(this.state.tipToClose)) && !(isNaN(this.state.tipToFar)) && !(isNaN(this.state.tipToDatum)) && !(isNaN(this.state.tipToCOP))) ?
            <LinearRegression
              labels={labels}
              channels={channels}
              strain={[this.props.strain.reading[channels[0]], this.props.strain.reading[channels[1]], this.props.strain.reading[channels[2]]]}
              orientation={(this.props.orientation.reading.b/10).toFixed(1)}
              tipToClose={this.state.tipToClose}
              tipToCOP={this.state.tipToCOP}
              tipToFar={this.state.tipToFar}
              tipToDatum={this.state.tipToDatum}
              datumToChannel={[this.props.manufacturerparameters.datumToChannelOne, this.props.manufacturerparameters.datumToChannelTwo, this.props.manufacturerparameters.datumToChannelThree]}
              weights={this.state.weights}
              ledToWeight={this.state.ledToWeight}
              onCalibrationComplete={this.handleSubmit}/> : <></>
            }
            {(!(isNaN(this.state.ledToWeight))) ?
            <LinearRegressionGen3
              labels={labels}
              channels={channels}
              strain={[this.props.strain.reading[channels[0]], this.props.strain.reading[channels[1]], this.props.strain.reading[channels[2]]]}
              orientation={(this.props.orientation.reading.b/10).toFixed(1)}
              datumToChannel={[this.props.manufacturerparameters.datumToChannelOne, this.props.manufacturerparameters.datumToChannelTwo, this.props.manufacturerparameters.datumToChannelThree]}
              weights={this.state.weights}
              ledToWeight={this.state.ledToWeight}
              onCalibrationComplete={this.handleSubmit}/> : <></>
            }
            {(!(isNaN(this.state.tipToBlade)) && !(isNaN(this.state.tipToShaft)) && !(isNaN(this.state.tipToCalWeight)) && !(isNaN(this.state.tipToDatum)) && !(isNaN(this.state.datumToDatum))) ?
            <LinearRegressionBladesOn
              labels={labels}
              channels={channels}
              shaft={this.state.shaft}
              strain={[this.props.strain.reading[channels[0]], this.props.strain.reading[channels[1]], this.props.strain.reading[channels[2]]]}
              orientation={orientation}
              orientationAlt={orientationAlt}
              tipToBlade={this.state.tipToBlade}
              tipToShaft={this.state.tipToShaft}
              tipToCalWeight={this.state.tipToCalWeight}
              tipToDatum={this.state.tipToDatum}
              datumToDatum={this.state.datumToDatum}
              datumToChannel={[this.props.manufacturerparameters.datumToChannelOne, this.props.manufacturerparameters.datumToChannelTwo, this.props.manufacturerparameters.datumToChannelThree]}
              weights={this.state.weights}
              onCalibrationComplete={this.handleSubmit}/> : <></>
            }
            {
              (this.state.lmsBoolean) ?
              <LMS
                strain={[this.props.strain.reading[channels[0]], this.props.strain.reading[channels[1]], this.props.strain.reading[channels[2]]]}
                zeroOffsets={[this.props.zerooffsets[0], this.props.zerooffsets[1], this.props.zerooffsets[2]]}
                scaleFactors={[this.props.scalefactors[0], this.props.scalefactors[1], this.props.scalefactors[2]]}/> : <></>
            }
          </GridCell>
          <GridCell span="3"></GridCell>
        </Grid>
        }
      </>
    );
  }
}

export default Calibration;
