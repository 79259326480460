import React from "react";
import { TextField } from '@rmwc/textfield';
import {
  DialogTitle,
  DialogContent
} from '@rmwc/dialog';

import '@material/dialog/dist/mdc.dialog.css';
import '@material/textfield/dist/mdc.textfield.css';
import '@material/floating-label/dist/mdc.floating-label.css';
import '@material/notched-outline/dist/mdc.notched-outline.css';
import '@material/line-ripple/dist/mdc.line-ripple.css';

class Name extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: props.name,
    };

    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(event) {
    this.setState({
      name: event.target.value
    }, () => {
      this.props.onChange(this.state.name);
    });
  }
  render() {
    return (
      <div>
        <DialogTitle>Power meter name</DialogTitle>
        <DialogContent style={{paddingTop: '15px'}}>
          <TextField
            outlined
            style={{width: '100%'}}
            label="Name"
            value={this.state.name}
            onChange={(event) => this.handleChange(event)}/>
        </DialogContent>
      </div>
    );
  };
};

export default Name;
