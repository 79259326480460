import React from "react";
import regression from 'regression';

import {
  DataTable,
  DataTableContent,
  DataTableHead,
  DataTableBody,
  DataTableHeadCell,
  DataTableRow,
  DataTableCell
} from '@rmwc/data-table';

import '@rmwc/data-table/data-table.css';

class LMS extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    }
  }

  render() {

    let a = ((this.props.strain[0] - this.props.zeroOffsets[0]) / (this.props.scaleFactors[0]/1000.0));
    let b = ((this.props.strain[1] - this.props.zeroOffsets[1]) / (this.props.scaleFactors[1]/1000.0));

    let tension = Math.sqrt((a * a) + (b * b));

    return (
      <>
        <DataTable style={{width: "100%"}}>
          <DataTableContent style={{width: "100%"}}>
            <DataTableHead>
              <DataTableRow onClick={() => this.handleHeaderClick()}>
                <DataTableHeadCell alignMiddle>
                  Tension (kg)
                </DataTableHeadCell>
              </DataTableRow>
            </DataTableHead>
              <DataTableBody>
              <DataTableRow>
                <DataTableCell alignMiddle style={{fontSize: "82px"}}>
                {!isNaN(tension.toFixed(0))? tension.toFixed(0) : 0}
                </DataTableCell>
              </DataTableRow>
              {/*<DataTableRow>
                <DataTableCell alignMiddle style={{fontSize: "22px"}}>
                {`${Math.sqrt(9)} ${a} ${b} ${(this.props.scaleFactors[0]/1000.0)} ${(this.props.strain[0] - this.props.zeroOffsets[0])}`}
                </DataTableCell>
                </DataTableRow>*/}
            </DataTableBody>
          </DataTableContent>
        </DataTable>
      </>
    )
  }
};

export default LMS;