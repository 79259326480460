import React from "react";
//import PropTypes from "prop-types";
import RecordingMessages from "./RecordingMessages";
import RecordingLimit from "./RecordingLimit";
import RecordingFrequency from "./RecordingFrequency";
import stop from "../../assets/stop.svg";
import play_arrow from "../../assets/play_arrow.svg";
import "./styles.css";
import { Fab } from '@rmwc/fab';
import { Grid, GridCell } from '@rmwc/grid';
import {
  Dialog,
  DialogActions,
  DialogButton
} from '@rmwc/dialog';

import {
  List,
  ListItem,
  ListItemText,
  ListItemPrimaryText,
  ListItemSecondaryText,
  ListGroup,
  ListDivider
} from '@rmwc/list';

import '@material/list/dist/mdc.list.css';
import '@material/dialog/dist/mdc.dialog.css';
import '@material/fab/dist/mdc.fab.css';
import '@material/layout-grid/dist/mdc.layout-grid.css';
import '@material/select/dist/mdc.select.css';
import '@material/floating-label/dist/mdc.floating-label.css';
import '@material/notched-outline/dist/mdc.notched-outline.css';
import '@material/line-ripple/dist/mdc.line-ripple.css';
import '@material/textfield/dist/mdc.textfield.css';
import '@rmwc/icon/icon.css';

class HighSpeedData extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      dialogOpen: false,
    };

    this.handleTriggerClick = this.handleTriggerClick.bind(this);
    this.handleLimitChange = this.handleLimitChange.bind(this);
    this.handleMessagesChange = this.handleMessagesChange.bind(this);
    this.handleFrequencyChange = this.handleFrequencyChange.bind(this);
  }

  componentDidMount() {
    this.props.enableStatistics();
    this.props.readRecordConfig();
  }

  componentWillUnmount() {
    this.props.disableStatistics();
  }

  formatTime(seconds) {
    return `${format(Math.floor(seconds / 60))}:${format(seconds % 60)}`;
  }

  handleTriggerClick() {
    this.setState({
      configuration: {
        ...this.props.configuration,
        recordingState: this.props.configuration.recordingState === 1 ? 0 : 1,
      },
    }, () => {
      this.props.writeRecordConfig(this.state.configuration);
    });
  }

  handleLimitChange(value) {
    this.setState({
      configuration: {
        ...this.state.configuration,
        recordingLimitSecs: value
      }
    })
  }

  handleMessagesChange(value) {
    this.setState({
      configuration: {
        ...this.state.configuration,
        recordingMessages: value
      }
    });
  }  
  
  handleFrequencyChange(value) {
    this.setState({
      configuration: {
        ...this.state.configuration,
        recordingFrequency: value
      }
    });
  }

  handleLimitSubmit() {
    if ((this.state.configuration !== undefined) && (this.state.configuration.recordingLimitSecs !== undefined)) {
      this.setState({
        configuration: {
          ...this.props.configuration,
          recordingLimitSecs: this.state.configuration.recordingLimitSecs,
        },
      }, () => {
        this.props.writeRecordConfig(this.state.configuration);
      });
    }
  }

  handleFrequencySubmit() {
    if ((this.state.configuration !== undefined) && (this.state.configuration.recordingFrequency !== undefined)) {
      this.setState({
        configuration: {
          ...this.props.configuration,
          recordingFrequency: this.state.configuration.recordingFrequency,
        },
      }, () => {
        this.props.writeRecordConfig(this.state.configuration);
      });
    }
  }

  handleMessagesSubmit() {
    if ((this.state.configuration !== undefined) && (this.state.configuration.recordingMessages !== undefined)) {
      this.setState({
        configuration: {
          ...this.props.configuration,
          recordingMessages: this.state.configuration.recordingMessages,
        },
      }, () => {
        this.props.writeRecordConfig(this.state.configuration);
      });
    }
  }

  openPopup(content) {
    let c;
    let oc;

    switch (content) {
      case "messages":
        c = <RecordingMessages messages={this.props.configuration.recordingMessages} onChange={this.handleMessagesChange.bind(this)}/>;
        oc = this.handleMessagesSubmit.bind(this);
        break;

      case "limit":
        c = <RecordingLimit limit={this.props.configuration.recordingLimitSecs} onChange={this.handleLimitChange.bind(this)}/>;
        oc = this.handleLimitSubmit.bind(this);
        break;

      case "frequency":
        c = <RecordingFrequency frequency={this.props.configuration.recordingFrequency} onChange={this.handleFrequencyChange.bind(this)}/>;
        oc = this.handleFrequencySubmit.bind(this);
        break;

      default:
        c = null;
        oc = () => {};
        break;
    }

    this.setState((ps) => ({
      ...ps,
      content: c,
      dialogOpen: true,
      onConfirm: oc
    }));
  }

  render() {
    let messages = `${(this.props.configuration.recordingMessages & 1) ? "[Diagnostic Data] " : ""}${(this.props.configuration.recordingMessages & 2) ? "[Strain Data] " : ""}${(this.props.configuration.recordingMessages & 4) ? "[Shaft Angle] " : ""}${(this.props.configuration.recordingMessages & 8) ? "[Blade Velocity] " : ""}${(this.props.configuration.recordingMessages & 16) ? "[Blade Accel] " : ""}${(this.props.configuration.recordingMessages & 32) ? "[Hand Force] " : ""}${(this.props.configuration.recordingMessages & 64) ? "[Hand Power] " : ""}${(this.props.configuration.recordingMessages & 128) ? "[Blade Force] " : ""}${(this.props.configuration.recordingMessages & 256) ? "[Force Verification] " : ""}`;
    return (
        <div>
            <Grid>
            <GridCell span="4"></GridCell>
            <GridCell span="4">
            <ListGroup>
            <List twoLine>
              <ListItem onClick={() => this.openPopup("messages")}>
                <ListItemText>
                  <ListItemPrimaryText>Data messages</ListItemPrimaryText>
                  <ListItemSecondaryText>{messages}</ListItemSecondaryText>
                </ListItemText>
              </ListItem>
              <ListDivider/>
              <ListItem onClick={() => this.openPopup("limit")}>
                <ListItemText>
                  <ListItemPrimaryText>Recording limit</ListItemPrimaryText>
                  <ListItemSecondaryText>{this.formatTime(this.props.configuration.recordingLimitSecs)}</ListItemSecondaryText>
                </ListItemText>
              </ListItem>
              <ListDivider/>
              <ListItem onClick={() => this.openPopup("frequency")}>
                <ListItemText>
                  <ListItemPrimaryText>Sample rate</ListItemPrimaryText>
                  <ListItemSecondaryText>{this.props.configuration.recordingFrequency} Hz</ListItemSecondaryText>
                </ListItemText>
              </ListItem>
              <ListDivider/>
              </List>
            </ListGroup>
            </GridCell>
            <GridCell span="4"></GridCell>
            </Grid>
            <Dialog
              open={this.state.dialogOpen}
              onClose={evt => {
                this.setState({
                  dialogOpen: false
                });
                if (evt.detail.action === "confirm") {
                  this.state.onConfirm()
                }
              }}
            >    
              {this.state.content}
              <DialogActions>
                <DialogButton action="close">Cancel</DialogButton>
                <DialogButton action="confirm" isDefaultAction>Confirm</DialogButton>
              </DialogActions>
            </Dialog>
            <Fab id="trigger" icon={this.props.configuration.recordingState ? stop : play_arrow} label={this.props.configuration.recordingState ? this.formatTime(this.props.statistics.reading.durationSecs) : "Start"} onClick={this.handleTriggerClick}/>
      </div>
    );
  }
}

const format = (value) => {
  if (value < 10) {
    return `0${value}`;
  } else {
    return `${value}`;
  }
}

/*HighSpeedData.propTypes = {
  temperature: PropTypes.object,
  pressure: PropTypes.object,
  humidity: PropTypes.object,
  co2: PropTypes.object,
  color: PropTypes.object,
  tvoc: PropTypes.object,
  changeTab: PropTypes.func,
  toggleTemperature: PropTypes.func,
  togglePressure: PropTypes.func,
  toggleCo2: PropTypes.func,
  toggleTvoc: PropTypes.func,
  toggleHumidity: PropTypes.func,
  toggleColor: PropTypes.func,
  toggleAll: PropTypes.func,
};*/

export default HighSpeedData;
